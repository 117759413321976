<template>
    <div @click="openAlert" class="alerts-list-row" tabindex="0" ref="alertRow">
        <!-- Avatar -->
        <Avatar
            v-if="alert.id"
            :color="alert.domainColour"
            :platform="Platform.Platform.GoogleAds"
            show-account-type
        >
            {{ alert.domainInitials }}
        </Avatar>
        <Skeleton v-else :width="36" :height="36" />

        <!-- Alert title -->
        <div class="account-name-container">
            <div v-if="alert.id" class="alert-name">
                <div class="alert-headline">{{ alert.title }}</div>

                <div class="alert-subline">
                    {{ lastUpdated }} in
                    <b>{{ alert.domainName }}</b>
                    <b v-if="alert.isCampaignLevel"> | {{ alert.body.campaign }}</b>
                </div>
            </div>
            <div v-else>
                <Skeleton :width="Math.random() * 120 + 100" />
            </div>
        </div>

        <!-- Alert status -->
        <div class="alert-status">
            <div v-if="!alert.id || alert.checked" class="alert-status-circle read"></div>
            <div v-else class="alert-status-circle unread"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { Alert, Platform } from '@opteo/types'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { setFloatingAlertId } from '@/composition/alerts/useAlert'
import { useShortKey, Avatar } from '@opteo/components-next'

import Skeleton from '@/components/util/Skeleton.vue'
import { Routes } from '@/router/routes'
import { useAlerts } from '@/composition/alerts/useAlerts'

export default defineComponent({
    name: 'AlertRow',
    components: { Avatar, Skeleton },
    props: { alert: { type: Object as PropType<Alert.Object> } },
    setup(props) {
        const alertRow = ref()
        const { alertsPopoverOpen } = useAlerts()

        const alertLink = computed(() => {
            return {
                name: Routes.Alert,
                params: {
                    alertId: props.alert?.hashId,
                },
            }
        })

        function openAlert() {
            alertsPopoverOpen.value = false
            setFloatingAlertId(props.alert?.hashId!)
        }

        const lastUpdated = computed(() =>
            formatDistanceToNow(parseISO(props.alert?.ts as string), { addSuffix: true })
        )

        useShortKey({
            keys: ['enter'],
            onPressCallBack: () => openAlert(),
            eventTarget: alertRow,
        })

        return {
            alertLink,
            lastUpdated,
            openAlert,
            alertRow,
            Platform,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

/* Alert List Item Container */
.alerts-list-row {
    @include flex;
    @include items-center;
    @include ph-24;
    @include opteo-background;
    @include br-20;
    @include mb-16;
    @include relative;
    height: 5rem;
    // Remove default styling from router-link component
    color: inherit;
    text-align: left;
    overflow: hidden;
    cursor: pointer;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
}
.alerts-list-row:focus {
    box-shadow: $opteo-shadow-focus;
    outline: none !important;
}
.alerts-list-row:active {
    box-shadow: $opteo-shadow-focus;
    outline: none !important;
    transform: translate3d(0, 1px, 0);
    -webkit-transform: translate3d(0, 1px, 0);
    -moz-transform: translate3d(0, 1px, 0);
}

.alerts-list-row:last-child {
    margin-bottom: 0;
}
.alerts-list-row::after {
    content: '';
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 50%);
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 6.5rem;
    z-index: 0;
}

.alert-headline {
    @include f-7;
    @include fw-500;
    @include lh-100;
    margin-bottom: 0.25rem;
    white-space: nowrap;
}
.alert-subline {
    @include f-10;
    @include fw-400;
    @include lh-130;
    @include o-30;
    white-space: nowrap;
}

.alerts-list-row .account-name-container {
    @include flex;
    @include items-center;
    @include ml-20;
}
.alert-status {
    @include absolute;
    @include z-2;
    right: 1.5rem;
    bottom: 50%;
    transform: translateY(50%);
}
.alert-status-circle {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 999rem;
}
.alert-status-circle.read {
    background: rgba(0, 0, 0, 0.1);
}
.alert-status-circle.unread {
    @include bg-opteo-red;
}
</style>
