import { ChartID, ChartLabel } from './types'

export const charts = [
    { id: ChartID.CLICKS, label: ChartLabel.CLICKS },
    { id: ChartID.COST, label: ChartLabel.COST },
    { id: ChartID.CONVERSIONS, label: ChartLabel.CONVERSIONS },
    { id: ChartID.CONV_VAL, label: ChartLabel.CONV_VAL },
    { id: ChartID.CPA, label: ChartLabel.CPA },
    { id: ChartID.ROAS, label: ChartLabel.ROAS },
    { id: ChartID.CTR, label: ChartLabel.CTR },
    { id: ChartID.CPC, label: ChartLabel.CPC },
    { id: ChartID.CR, label: ChartLabel.CR },
    { id: ChartID.IMPRESSIONS, label: ChartLabel.IMPRESSIONS },
    { id: ChartID.VAL_PER_CONV, label: ChartLabel.VAL_PER_CONV },
    { id: ChartID.SEARCH_IMP_SHARE, label: ChartLabel.SEARCH_IMP_SHARE },
    { id: ChartID.QS, label: ChartLabel.QS },
    { id: ChartID.QS_COMPONENTS, label: ChartLabel.QS_COMPONENTS },
]
