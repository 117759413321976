<template>
    <div>
        <oTable
            :headers="costTableHeaders"
            :items="costTableRows"
            :per-page="6"
            orderBy="totalCostSaved"
            order="DESC"
            :borderRadius="24"
            class="impact-statistics-cost-section-table"
        >
            <template v-slot:header.accountName>
                <div class="table-search-container">
                    <Label class="table-search-label">Account Name</Label>
                    <Spacer width="1rem" />
                    <oInput
                        ref="searchInput"
                        type="text"
                        class="account-search-input"
                        name="accountSearchInputCost"
                        autocomplete="off"
                        v-model="searchedAccount"
                    />
                </div>
            </template>
            <template v-slot:column.accountName="cell">
                <ImpactStatsAccountNameCell
                    :domainName="cell.value.name"
                    :domainColor="cell.value.color"
                    :platform="cell.value.platform"
                    :initials="cell.value.initials"
                    :validating="validating"
                />
            </template>
            <template v-slot:column.accountBreakdown="cell">
                <oButton color="white" size="small" @clicked="openModal(cell.value)">
                    Detailed View
                </oButton>
            </template>
            <template v-slot:column.avgSavedPerImp="cell">
                <Money v-if="!validating" currency="USD" :value="cell.value" />
                <Skeleton v-else-if="validating" width="3rem" />
            </template>
            <template v-slot:column.totalCostSaved="cell">
                <Money v-if="!validating" currency="USD" :value="cell.value" />
                <Skeleton v-else-if="validating" width="3rem" />
            </template>
            <template v-if="validating" v-slot:column.impCount>
                <Skeleton width="2rem" />
            </template>
        </oTable>
        <!-- Account Breakdown Modal -->
        <Modal
            v-model="costBreakdownModalOpen"
            title="Cost Savings Breakdown"
            width="78.5rem"
            @closed="resetNumberOfVisibleRows"
        >
            <template #content>
                <ImpactStatisticsImprovementRow
                    v-for="(row, index) in slicedRows"
                    :improvement="row"
                    :class="index === slicedRows.length - 1 ? 'last' : ''"
                    @click="openInNewTab(row.open)"
                />
                <div
                    v-if="modalTableRows.length > slicedRows.length"
                    class="show-more-button-wrapper"
                >
                    <oButton color="white" @clicked="showMoreRows">Show More</oButton>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script setup lang="ts">
// vue
import { computed, ref, PropType } from 'vue'
// types
import { Account, IS } from '@opteo/types'
import { AccountData } from '@/composition/impactStatistics/useImpactStatistics'
// local components
import ImpactStatsAccountNameCell from '@/components/impactStatistics/ImpactStatsAccountNameCell.vue'
import ImpactStatisticsImprovementRow from '@/components/impactStatistics/ImpactStatisticsImprovementRow.vue'
import Skeleton from '@/components/util/Skeleton.vue'
// Composition
import useImpactStatistics from '@/composition/impactStatistics/useImpactStatistics'
// components-next
import { oTable, Label, Spacer, oInput, Money, oButton, Modal } from '@opteo/components-next'
// lodash
import includes from 'lodash-es/includes'
import sortBy from 'lodash-es/sortBy'

interface CostTableRowData {
    id: string | number
    accountName: AccountData
    impCount: number
    avgSavedPerImp: number
    totalCostSaved: number
    accountBreakdown: AccountBreakdown
}

interface ModalTableRow {
    account: AccountData
    title: string
    location: {}
    savings: number
    date: string
    open: { domainId: string | number; improvementId: string | number }
    hasBudgetAutoPause?: boolean
}

export type AccountBreakdown = {
    improvements: IS.ImprovementCostSavings[]
    pauseSpend: IS.PauseSpendCostSavings
    domainInfo: AccountData
    domainId: string | number
}

const props = defineProps({
    data: {
        type: Array as PropType<CostTableRowData[]>,
        required: true,
    },
    validating: {
        type: Boolean,
        default: false,
    },
})

const {
    formatDateFromTimestamp,
    generateImprovementRouterLink,
    generatePauseSpendRouterLink,
    openUrlInNewTab,
    viewportHeight,
} = useImpactStatistics()

const costBreakdownModalOpen = ref(false)
const searchedAccount = ref('')
const modalTableRows = ref<ModalTableRow[]>([])
const maxModalRows = computed(() => Math.floor((0.45 * viewportHeight.value) / 74))

// Costs savings table
const costTableHeaders = [
    { key: 'accountName', text: 'Account Name', noPadding: true },
    { key: 'totalCostSaved', text: 'Cost Saved', sortable: true },
    { key: 'impCount', text: 'Imp. Count', sortable: true },
    { key: 'avgSavedPerImp', text: 'Per Imp.', sortable: true },
    { key: 'accountBreakdown', text: 'Actions' },
]

const costTableRows = computed(() => {
    if (!searchedAccount.value) return props.data
    else
        return props.data.filter(item =>
            includes(item.accountName.name.toLowerCase(), searchedAccount.value.toLowerCase())
        )
})

// Modal
const openModal = (accountData: AccountBreakdown) => {
    // Generate table cells from data
    modalTableRows.value = generateCostModalRows(accountData)
    // Open the modal
    costBreakdownModalOpen.value = true
}
const modalTableHeaders = [
    { key: 'account', text: 'Account', noPadding: true },
    { key: 'title', text: 'Title' },
    { key: 'location', text: 'Location' },
    { key: 'savings', text: 'Costs Saved' },
    { key: 'date', text: 'Date Completed' },
    { key: 'open', text: 'Open' },
]
const generateCostModalRows = (data: AccountBreakdown) => {
    // Format data as table rows
    const rows: ModalTableRow[] = data.improvements.map(instance => {
        return {
            account: data.domainInfo,
            title: instance.title,
            location: instance.location,
            savings: instance.monthlyCostSavingsUSD,
            date: formatDateFromTimestamp(instance.ts),
            open: { domainId: data.domainId, improvementId: instance.improvementId },
        }
    })
    // If pause spend is true OR there are savings, format data to match imps and add it to the list
    if (data.pauseSpend.hasBudgetAutoPause === true || data.pauseSpend.monthlyCostSavingsUSD > 0) {
        rows.push({
            account: data.domainInfo,
            title: 'Pause Spend When Over Budget',
            location: [{ entity: 'account-level', label: 'Account Level' }],
            savings: data.pauseSpend.monthlyCostSavingsUSD,
            hasBudgetAutoPause: data.pauseSpend.hasBudgetAutoPause,
            date: '',
            open: { domainId: data.domainId, improvementId: 'pauseSpend' },
        })
    }
    // Return table rows, sorted in descending order according to costs saved
    return sortBy(rows, ['savings']).reverse()
}

// open in new tab
const openInNewTab = (instanceData: {
    domainId: string | number
    improvementId: string | number
}) => {
    // For pause spend
    if (instanceData.improvementId === 'pauseSpend') {
        openUrlInNewTab(generatePauseSpendRouterLink({ domainId: instanceData.domainId }))
    }
    // for regular improvements
    openUrlInNewTab(
        generateImprovementRouterLink({
            domainId: instanceData.domainId,
            improvementId: instanceData.improvementId,
        })
    )
}

const numberOfVisibleRows = ref(50)
const slicedRows = computed(() => {
    return modalTableRows.value.slice(0, numberOfVisibleRows.value)
})
const showMoreRows = () => {
    numberOfVisibleRows.value = numberOfVisibleRows.value + 50
}
const resetNumberOfVisibleRows = () => {
    numberOfVisibleRows.value = 50
}
</script>

<!-- Scoped -->
<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

@media screen and (max-width: 1279px) {
    .account-search-input {
        display: none;
    }
}

@media screen and (min-width: 1280px) {
    .account-search-input {
        max-width: 12rem;
        @include relative;
    }
    .account-search-input :deep(input) {
        padding: 10px 14px 10px 38px;
    }
    .account-search-input::before {
        content: '';
        position: absolute;
        left: 14px;
        top: 0;
        bottom: 0;
        width: 12px;
        background: url('@/assets/img/searchIcon.svg') center / contain no-repeat;
    }
}

// Modal
.show-more-button-wrapper {
    width: 100%;
    @include flex-center;
    @include mt-24;
}
</style>

<!-- Unscoped -->
<style lang="scss">
@media screen and (max-width: 1279px) {
    .impact-statistics-cost-section-table td:not(:first-child),
    .impact-statistics-cost-section-table th:not(:first-child) {
        padding-left: 1.125rem;
        padding-right: 1.125rem;
    }
    .impact-statistics-cost-section-table td:last-child {
        padding-right: 1.5rem;
    }
    .impact-statistics-cost-section-table .impact-stats-table-domain-name {
        max-width: 16rem;
    }
}

@media screen and (min-width: 1280px) {
    .impact-statistics-cost-section-table td:last-child {
        padding-right: 2.125rem;
    }
}
</style>
