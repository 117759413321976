<template>
    <div
        ref="selectClientTippy"
        :class="{ pointer: (miniAccountList?.length ?? 0) > 1 }"
        class="client-selector-container"
        @keyup.tab.prevent="onTabKey"
        tabindex="0"
    >
        <div class="client-selector">
            <Avatar
                v-if="accountName"
                :color="accountColor"
                :platform="accountPlatform"
                show-account-type
            >
                {{ accountInitials }}
            </Avatar>
            <Skeleton v-else :width="36" :height="36" />
            <p v-if="accountName" class="client-name" :style="{ color: accountColor }">
                {{ accountName }}
            </p>
            <SelectArrowIcon v-if="accountName" :hex="accountColor" class="client-selector-arrow" />
            <div class="client-selector-skeleton" v-else>
                <Spacer width="0.75rem" />
                <Skeleton :width="Math.random() * 80 + 100" />
            </div>
        </div>
    </div>

    <div ref="selectClientTippyContent" class="select-client-dropdown">
        <!-- Search Bar -->
        <div class="select-client-dropdown-search-bar" @keydown.tab.prevent="onTabKey">
            <div class="select-client-dropdown-search-button">
                <svg width="14" height="14">
                    <path
                        d="M10.0317379 8.6175243l3.503796 3.503796-1.4142136 1.4142136-3.503796-3.503796C7.7314831 10.642436 6.6574983 11 5.5 11 2.4624339 11 0 8.5375661 0 5.5S2.4624339 0 5.5 0 11 2.4624339 11 5.5c0 1.1574983-.357564 2.2314831-.9682621 3.1175243zM5.5 9C7.4329966 9 9 7.4329966 9 5.5S7.4329966 2 5.5 2 2 3.5670034 2 5.5 3.5670034 9 5.5 9z"
                        fill="#006dff"
                        fill-rule="evenodd"
                    />
                </svg>
            </div>
            <div class="select-client-dropdown-search">
                <oForm
                    @submitted="miniAccountList[0] && changeAccount(miniAccountList[0].accountId)"
                >
                    <oInput
                        ref="searchInput"
                        v-model="domainSearch"
                        placeholder="Search.."
                        type="text"
                        class="select-client-dropdown-search-input"
                        name="domainSearchInput"
                        autocomplete="off"
                    />
                </oForm>
            </div>
        </div>
        <!-- Client List -->
        <perfect-scrollbar class="select-client-dropdown-scroll-container">
            <div class="w-100 relative">
                <div
                    v-for="(account, index) in miniAccountList"
                    id="clientItem"
                    :key="account.accountId"
                    :class="[!index ? 'hit-enter' : '']"
                    class="select-client-dropdown-client"
                    :style="{ color: account.color }"
                    @click="changeAccount(account.accountId)"
                >
                    <Avatar
                        :color="account.color"
                        :platform="account.platform"
                        show-account-type
                        account-type-size="small"
                    >
                        {{ getDomainInitials(account.name) }}
                    </Avatar>
                    <span class="select-client-dropdown-client-name">
                        {{ account.name }}
                    </span>
                    <span class="select-client-dropdown-client-imps">
                        {{ account.tasksAvailable }}
                    </span>
                </div>
                <div v-if="miniAccountList.length === 0" class="empty-state-container">
                    <img
                        src="@/assets/img/no-results-small.png"
                        class="empty-state-image"
                        alt="No accounts found"
                    />
                    <div class="empty-state-text">
                        We couldn't find an account matching your search query.
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useTippy } from 'vue-tippy'
import sortBy from 'lodash-es/sortBy'
import { oForm, oInput, Spacer, SelectArrowIcon, Avatar } from '@opteo/components-next'
import { RouteRecordName, useRoute, useRouter } from 'vue-router'

import { getPlatformFromId } from '@/lib/globalUtils'
import { useAccountList } from '@/composition/user/useAccountList'
import { getDomainInitials } from '@/composition/domain/useDomain'
import Skeleton from '@/components/util/Skeleton.vue'
import { Account } from '@opteo/types'
import { Routes } from '@/router/routes'
import { useAccount } from '@/composition/account/useAccount'
import { searchForAccountMatcher } from '@/lib/search'
import { Platform } from '@opteo/types'

export default defineComponent({
    name: 'DomainSelector',
    components: { Avatar, Skeleton, oForm, oInput, Spacer, SelectArrowIcon },
    setup() {
        const { sortedDomainList, mutateDomainList } = useAccountList()
        const { accountId, accountColor, accountName, accountInitials, accountPlatform } =
            useAccount()

        const route = useRoute()
        const router = useRouter()

        const selectClientTippy = ref()
        const selectClientTippyContent = ref()
        let tippyOpen = false

        const searchInput = ref()
        const domainSearch = ref('')

        const { hide: hideTippy, show: showTippy } = useTippy(selectClientTippy, {
            content: () => selectClientTippyContent.value,
            theme: 'template-mode',
            placement: 'bottom-start',
            offset: [-26, 8],
            maxWidth: 350,
            zIndex: 999999,
            touch: true,
            interactive: true,
            animateFill: true,
            trigger: 'click',
            appendTo: () => document.body,
            popperOptions: {
                strategy: 'fixed',
            },
            onMount: () => {
                searchInput.value?.inputRef.focus()
                tippyOpen = true
                document.body.classList.add('no-scroll')
            },
            onHidden: () => {
                tippyOpen = false
                domainSearch.value = ''
                document.body.classList.remove('no-scroll')
            },
            onClickOutside: (instance, event) => {
                const clickedElement = event.target as HTMLElement
                if (!!clickedElement.closest('[data-tippy-root]')) return

                hideTippy()
            },
        })

        const miniAccountList = computed(() => {
            return sortBy(
                sortedDomainList.value
                    .filter(account => searchForAccountMatcher(domainSearch.value, account))
                    .filter(account => {
                        // TODO: Remove this condition when fully migrated to accounts
                        if (account.platform === Platform.Platform.GoogleAds) {
                            return account.accountId.toString() !== accountId.value
                        }
                        return account.accountId !== accountId.value
                    }),
                account => -Date.parse(account.lastVisitedAt as string)
            ).slice(0, 20)
        })

        const onTabKey = () => (tippyOpen ? hideTippy() : showTippy())

        // If navigating from Microsoft, only allow access to toolkit or improvements
        // If navigating to an unsupported route for an account, redirect to improvements
        const changeAccount = async (accountId: Account.ID) => {
            hideTippy()

            const platform = getPlatformFromId(accountId)

            const permittedRoutes =
                platform === Platform.Platform.MicrosoftAds
                    ? [
                          Routes.Improvements,
                          Routes.ToolkitTools,
                          Routes.PerformanceGraphs,
                          Routes.PerformanceTable,
                      ]
                    : Object.values(Routes)

            const isRoutePermitted = permittedRoutes.includes(route.name as Routes)

            const newRoute = (
                isRoutePermitted ? route.name : Routes.Improvements
            ) as RouteRecordName

            router.push({
                name: newRoute,
                params: { accountId },
            })
            mutateDomainList(accountId, { lastVisitedAt: new Date().toISOString() })
        }

        return {
            selectClientTippy,
            selectClientTippyContent,
            searchInput,
            domainSearch,
            miniAccountList,
            accountName,
            accountColor,
            accountInitials,
            accountPlatform,
            getDomainInitials,
            changeAccount,
            onTabKey,
            Platform,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.client-selector-container {
    outline: none;
    @include no-select;
    @include flex;
    @include items-center;
}
.client-selector {
    @include flex;
    @include items-center;
}
.client-selector-skeleton {
    @include flex;
    @include items-center;
}
.client-name {
    @include f-7;
    @include fw-500;
    @include ml-16;
    transform: translateY(-1px);
    white-space: nowrap;
    max-width: 11.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.client-selector-arrow {
    @include ml-8;
    transform: translateX(0.125rem);
}
.select-client-dropdown {
    @include z-max;
    @include opteo-background;
    @include br-16;
    margin: 0.125rem 1.5rem 1.5rem 1.5rem;
    box-shadow: $opteo-shadow-l;
    width: 19rem;
    overflow: hidden;
}
.select-client-dropdown-search-bar {
    @include flex;
    @include items-center;
    border-bottom: 1px solid $opteo-light-gray;
    padding: 1rem 1.5rem;
}
.select-client-dropdown-search-button {
    @include br-999;
    @include flex;
    @include items-center;
    @include justify-center;
    box-shadow: $opteo-shadow;
    width: 2.25rem;
    height: 2.25rem;
}
.select-client-dropdown-search-input {
    width: 12.75rem;
}
.select-client-dropdown-search {
    @include ml-16;
}
.select-client-dropdown-scroll-container {
    height: 100%;
    max-height: 16.5rem;
}
.select-client-dropdown-client {
    @include ph-24;
    @include pv-16;
    @include flex;
    @include items-center;
    @include relative;
    @include pointer;
    border-bottom: 1px solid $opteo-light-gray;
}
.select-client-dropdown-client:last-child {
    border-bottom: none;
}
.select-client-dropdown-client:hover {
    background: $opteo-lighter-gray;
}
.select-client-dropdown-client-name {
    @include f-8;
    @include fw-500;
    @include lh-150;
    margin-left: 1rem;
    max-width: 10.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.select-client-dropdown-client-imps {
    @include f-10;
    @include fw-500;
    @include lh-150;
    @include br-999;
    @include flex;
    @include items-center;
    @include justify-center;
    width: 1.5rem;
    height: 1.5rem;
    box-shadow: $opteo-shadow;
    margin-left: 0.5rem;
}
@media screen and (min-width: $mq-1025-min) {
    .hit-enter::after {
        content: 'Enter';
        @include absolute;
        @include bg-opteo-white;
        @include br-4;
        right: 1.5rem;
        font-size: 0.625rem;
        color: #000;
        box-shadow: $opteo-shadow;
        height: auto;
        padding: 6px 8px 6px 8px;
        z-index: 2;
    }
    .hit-enter::before {
        content: '';
        background: white;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 25%);
        width: 5rem;
        right: 1rem;
        height: 100%;
        position: absolute;
        z-index: 1;
    }
}

.select-client-dropdown-client:hover::before {
    background: $opteo-lighter-gray;
    background: linear-gradient(90deg, rgba(250, 250, 253, 0) 0%, $opteo-lighter-gray 25%);
}
.empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 16.5rem;
}
.empty-state-image {
    width: 4.375rem;
    @include mb-16;
}
.empty-state-text {
    @include f-9;
    @include lh-150;
    @include tc;
    width: 11rem;
}
</style>

<style>
/* Unscoped Styles for Tippy Theme */
.tippy-box[data-theme~='domain-selector-tippy-theme'] {
    overflow: visible !important;
}
</style>
