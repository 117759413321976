import { computed, ref } from 'vue'

import { useDomain } from '@/composition/domain/useDomain'
import { useAPI, Endpoint } from '@/composition/api/useAPI'
import { scrollTo } from '@/lib/globalUtils'

import { Segments, Targets } from '@opteo/types'
import { usePerformanceControls } from './usePerformanceControls'

export function useSegments() {
    const { domainId } = useDomain()

    const { selectedGroup, campaignGroupItems, selectedGroupId, sidebarOpen, startDate, endDate } =
        usePerformanceControls()

    const isUsingCpa = computed(
        () => selectedGroup.value?.performanceMode === Targets.PerformanceMode.CPA
    )

    const isUsingROAS = computed(
        () => selectedGroup.value?.performanceMode === Targets.PerformanceMode.ROAS
    )
    const loadingDomain = computed(() => !domainId.value)
    const bestSegmentsSelected = ref(true)

    const filters = computed(() => {
        return {
            selected_group: selectedGroup.value,
        }
    })

    const uniqueId = computed(
        () =>
            `${domainId.value}||${JSON.stringify(filters.value)}||${startDate?.value}||${
                endDate?.value
            }`
    )

    const { data: segmentsData, loading: segmentsLoading } =
        useAPI<Segments.GetAllSegmentsResponse>(Endpoint.GetAllSegments, {
            waitFor: () => selectedGroup.value && !loadingDomain.value,
            body: () => {
                return {
                    from_date: startDate?.value,
                    to_date: endDate?.value,
                    filters: filters.value,
                    domain_id: domainId.value,
                }
            },
            uniqueId: () => uniqueId.value,
        })

    const allSegments = computed(() => segmentsData.value?.segments)
    const worstSegments = computed(() => {
        if (!segmentsData.value) {
            return
        }
        return segmentsData.value.worst_segments.map(row => {
            return {
                ...row,
                labelIncSegment: `${row.segment_label} (in ${row.real_name})`,
            }
        })
    })
    const bestSegments = computed(() => {
        if (!segmentsData.value) {
            return
        }
        return segmentsData.value.best_segments.map(row => {
            return {
                ...row,
                labelIncSegment: `${row.segment_label} (in ${row.real_name})`,
            }
        })
    })

    // Best Segments
    const bestSegmentsHeaders = computed(() => [
        { key: 'segment_label', text: 'Segment', sortable: true, noPadding: true },
        isUsingCpa.value
            ? { key: 'cpa', text: 'CPA', sortable: true, noPadding: true, width: 88 }
            : {
                  key: 'roas',
                  text: 'ROAS',
                  sortable: true,
                  noPadding: true,
                  width: 92,
              },
        { key: 'target', text: 'Target', sortable: true, noPadding: true, width: 92 },
        { key: 'difference', text: 'Diff.', sortable: true, noPadding: true, width: 92 },
    ])

    // Worst Segments
    const worstSegmentsHeaders = computed(() => [
        { key: 'segment_label', text: 'Segment', sortable: true, noPadding: true },
        isUsingCpa.value
            ? { key: 'cpa', text: 'CPA', sortable: true, noPadding: true, width: 88 }
            : {
                  key: 'roas',
                  text: 'ROAS',
                  sortable: true,
                  noPadding: true,
                  width: 92,
              },
        { key: 'target', text: 'Target', sortable: true, noPadding: true, width: 92 },
        { key: 'difference', text: 'Diff.', sortable: true, noPadding: true, width: 92 },
    ])

    const scrollToSegment = (targetSegment: string) => {
        scrollTo(targetSegment, 'smooth', 96)
    }

    const segmentKeys = Object.values(Segments.Segment)

    return {
        allSegments,
        segmentsLoading,
        selectedGroupId,
        selectedGroup,
        campaignGroupItems,
        bestSegmentsSelected,
        sidebarOpen,
        bestSegments,
        worstSegments,
        scrollTo,
        domainId,
        segmentsData,
        bestSegmentsHeaders,
        worstSegmentsHeaders,
        scrollToSegment,
        segmentKeys,
        isUsingCpa,
        isUsingROAS,
    }
}
