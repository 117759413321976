<template>
    <PageContent>
        <!-- Best & Worst Segments -->
        <div v-if="!segmentsLoading && bestSegments && worstSegments">
            <div class="best-worst-container">
                <!--  Best Segments -->
                <div class="best-segments">
                    <Text as="h4" weight="600" size="f-5" style="letter-spacing: -0.0375rem">
                        Best Segments
                    </Text>
                    <Spacer height="2rem" />
                    <!-- IF THE FIRST SEGMENT TYPE HAS LOADED AND THERES DATA IN BEST SEGMENTS -->
                    <oTable
                        v-if="bestSegments"
                        :headers="bestSegmentsHeaders"
                        :items="bestSegments"
                        :per-page="aboveMobile ? 6 : 1"
                        :fixed-layout="true"
                        :border-radius="20"
                        responsive-breakpoint="767px"
                        order-by="difference"
                        :order="isUsingCpa ? 'ASC' : 'DESC'"
                    >
                        <!-- Segment Header -->
                        <template #header.segment_label>
                            <div class="segment-label-column">
                                <span class="segment-header">Segment</span>
                            </div>
                        </template>
                        <!-- Segment -->
                        <template #column.segment_label="cellData">
                            <Tooltip
                                placement="top-start"
                                :offset="[14, -8]"
                                :max-width="600"
                                :content="
                                    cellData.row.labelIncSegment.length > 34
                                        ? cellData.row.labelIncSegment
                                        : ''
                                "
                            >
                                <Text
                                    as="p"
                                    size="f-8"
                                    class="segment-label-column"
                                    @click="scrollToSegment(cellData.row.target_segment)"
                                >
                                    <span class="segment-label">
                                        {{ cellData.row.labelIncSegment }}
                                    </span>
                                </Text>
                            </Tooltip>
                        </template>
                        <!-- CPA -->
                        <template v-if="isUsingCpa" #column.cpa="cellData">
                            <Text size="f-8" as="span">
                                <Money :value="cellData.value" />
                            </Text>
                        </template>
                        <!-- ROAS -->
                        <template v-if="isUsingROAS" #column.roas="cellData">
                            <Text size="f-8" as="span">
                                <Roas :value="cellData.value" />
                            </Text>
                        </template>
                        <!-- Target -->
                        <template #column.target="cellData">
                            <Text as="p" size="f-8">
                                <span class="segments-table-label">
                                    <Money v-if="isUsingCpa" :value="cellData.value" />
                                    <Roas v-if="isUsingROAS" :value="cellData.value" />
                                </span>
                            </Text>
                        </template>
                        <!-- Diff -->
                        <template #column.difference="cellData">
                            <div v-if="isUsingCpa">
                                <Text
                                    v-if="cellData.value < 0"
                                    as="p"
                                    size="f-8"
                                    class="segments-table-column"
                                    color="green"
                                    weight="500"
                                >
                                    <span
                                        class="segments-table-label"
                                        style="padding-right: 1.5rem"
                                    >
                                        <Percent :value="cellData.value" :include-sign="true" />
                                    </span>
                                </Text>
                                <Text
                                    v-else
                                    as="p"
                                    size="f-8"
                                    class="segments-table-column"
                                    color="red"
                                    weight="500"
                                >
                                    <span
                                        class="segments-table-label"
                                        style="padding-right: 1.5rem"
                                    >
                                        <Percent :value="cellData.value" :include-sign="true" />
                                    </span>
                                </Text>
                            </div>
                            <div v-else>
                                <Text
                                    v-if="cellData.value * 100 < 0"
                                    as="p"
                                    size="f-8"
                                    class="segments-table-column"
                                    color="red"
                                    weight="500"
                                >
                                    <span
                                        class="segments-table-label"
                                        style="padding-right: 1.5rem"
                                    >
                                        <Percent :value="cellData.value" :include-sign="true" />
                                    </span>
                                </Text>
                                <Text
                                    v-else
                                    as="p"
                                    size="f-8"
                                    class="segments-table-column"
                                    color="green"
                                    weight="500"
                                >
                                    <span
                                        class="segments-table-label"
                                        style="padding-right: 1.5rem"
                                    >
                                        <Percent :value="cellData.value" :include-sign="true" />
                                    </span>
                                </Text>
                            </div>
                        </template>
                    </oTable>

                    <div v-else class="in-table-empty-state-container">
                        <!-- No conversions empty state -->
                        <img
                            src="@/assets/img/no-results-account-centre.png"
                            class="image"
                            alt="No conversions found"
                            style="width: 220px"
                        />
                        <Spacer height="2rem" />
                        <div class="center" style="max-width: 200px">
                            <Text as="p" size="f-9" align="center" class="text">
                                We couldn't find any conversions for the selected campaign group.
                                <Spacer height="0.75rem" />
                                Try another group by opening the menu at the top right of the page.
                            </Text>
                        </div>
                    </div>
                </div>
                <!--  Worst Segments -->
                <div class="worst-segments">
                    <Text as="h4" weight="600" size="f-5" style="letter-spacing: -0.0375rem">
                        Worst Segments
                    </Text>
                    <Spacer height="2rem" />
                    <oTable
                        v-if="worstSegments"
                        :headers="worstSegmentsHeaders"
                        :items="worstSegments"
                        :per-page="aboveMobile ? 6 : 1"
                        :fixed-layout="true"
                        :border-radius="20"
                        responsive-breakpoint="767px"
                        order-by="difference"
                        :order="isUsingCpa ? 'DESC' : 'ASC'"
                    >
                        <!-- Segment Header -->
                        <template #header.segment_label>
                            <div class="segment-label-column">
                                <span class="segment-header">Segment</span>
                            </div>
                        </template>
                        <!-- Segment -->
                        <template #column.segment_label="cellData">
                            <Tooltip
                                placement="top-start"
                                :offset="[14, -8]"
                                :max-width="600"
                                :content="
                                    cellData.row.labelIncSegment.length > 34
                                        ? cellData.row.labelIncSegment
                                        : ''
                                "
                            >
                                <Text
                                    as="p"
                                    size="f-8"
                                    class="segment-label-column"
                                    @click="scrollToSegment(cellData.row.target_segment)"
                                >
                                    <span class="segment-label">
                                        {{ cellData.row.labelIncSegment }}
                                        <!-- {{ cellData.value }} (in {{ cellData.row?.real_name }}) -->
                                    </span>
                                </Text>
                            </Tooltip>
                        </template>
                        <!-- CPA -->
                        <template v-if="isUsingCpa" #column.cpa="cellData">
                            <Text size="f-8" as="span">
                                <Money :value="cellData.value" />
                            </Text>
                        </template>
                        <!-- ROAS -->
                        <template v-if="isUsingROAS" #column.roas="cellData">
                            <Text size="f-8" as="span">
                                <Roas :value="cellData.value" />
                            </Text>
                        </template>
                        <!-- Target -->
                        <template #column.target="cellData">
                            <Text as="p" size="f-8">
                                <span class="segments-table-label">
                                    <Money v-if="isUsingCpa" :value="cellData.row.target" />
                                    <Roas v-if="isUsingROAS" :value="cellData.row.target" />
                                </span>
                            </Text>
                        </template>
                        <!-- Diff -->
                        <template #column.difference="cellData">
                            <div v-if="isUsingCpa">
                                <Text
                                    v-if="cellData.value < 0"
                                    as="p"
                                    size="f-8"
                                    class="segments-table-column"
                                    color="green"
                                    weight="500"
                                >
                                    <span
                                        class="segments-table-label"
                                        style="padding-right: 1.5rem"
                                    >
                                        <Percent :value="cellData.value" :include-sign="true" />
                                    </span>
                                </Text>
                                <Text
                                    v-else
                                    as="p"
                                    size="f-8"
                                    class="segments-table-column"
                                    color="red"
                                    weight="500"
                                >
                                    <span
                                        class="segments-table-label"
                                        style="padding-right: 1.5rem"
                                    >
                                        <Percent :value="cellData.value" :include-sign="true" />
                                    </span>
                                </Text>
                            </div>
                            <div v-else>
                                <Text
                                    v-if="cellData.value * 100 < 0"
                                    as="p"
                                    size="f-8"
                                    class="segments-table-column"
                                    color="red"
                                    weight="500"
                                >
                                    <span
                                        class="segments-table-label"
                                        style="padding-right: 1.5rem"
                                    >
                                        <Percent :value="cellData.value" :include-sign="true" />
                                    </span>
                                </Text>
                                <Text
                                    v-else
                                    as="p"
                                    size="f-8"
                                    class="segments-table-column"
                                    color="green"
                                    weight="500"
                                >
                                    <span
                                        class="segments-table-label"
                                        style="padding-right: 1.5rem"
                                    >
                                        <Percent :value="cellData.value" :include-sign="true" />
                                    </span>
                                </Text>
                            </div>
                        </template>
                    </oTable>
                    <div v-else class="in-table-empty-state-container">
                        <!-- No conversions empty state -->
                        <img
                            src="@/assets/img/no-results-account-centre.png"
                            class="image"
                            alt="No conversions found"
                            style="width: 220px"
                        />
                        <Spacer height="2rem" />
                        <div class="center" style="max-width: 200px">
                            <Text as="p" size="f-9" align="center" class="text">
                                We couldn't find any conversions for the selected campaign group.
                                <Spacer height="0.75rem" />
                                Try another group by opening the menu at the top right of the page.
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Best & Worst Skeletons -->
        <div v-if="segmentsLoading">
            <div class="best-worst-container">
                <!--  Best Segments -->
                <div class="best-segments">
                    <Text as="h4" weight="600" size="f-5" style="letter-spacing: -0.0375rem">
                        Best Segments
                    </Text>
                    <Spacer height="2rem" />
                    <oTable
                        :headers="bestSegmentsHeaders"
                        :items="new Array(24).fill({})"
                        :per-page="aboveMobile ? 6 : 1"
                        :fixed-layout="true"
                        :border-radius="20"
                        responsive-breakpoint="767px"
                        order-by="profit"
                        order="DESC"
                    >
                        <!-- Segment Header -->
                        <template #header.segment_label>
                            <div class="segment-label-column">
                                <span class="segment-header">Segment</span>
                            </div>
                        </template>
                        <!-- Segment -->
                        <template #column.segment_label>
                            <Text as="p" size="f-8" class="segment-label-column">
                                <span class="segment-label">
                                    <Skeleton :height="16" :width="Math.random() * 120 + 80" />
                                </span>
                            </Text>
                        </template>
                        <!-- CPA -->
                        <template #column.cpa>
                            <Skeleton :height="16" :width="32" />
                        </template>
                        <!-- ROAS -->
                        <template #column.roas>
                            <Skeleton :height="16" :width="56" />
                        </template>
                        <!-- Target -->
                        <template #column.target>
                            <Skeleton :height="16" :width="56" />
                        </template>
                        <!-- Diff -->
                        <template #column.difference>
                            <Skeleton :height="16" :width="56" />
                        </template>
                    </oTable>
                </div>
                <!--  Worst Segments -->
                <div class="worst-segments">
                    <Text as="h4" weight="600" size="f-5" style="letter-spacing: -0.0375rem">
                        Worst Segments
                    </Text>
                    <Spacer height="2rem" />
                    <oTable
                        :headers="worstSegmentsHeaders"
                        :items="new Array(24).fill({})"
                        :per-page="aboveMobile ? 6 : 1"
                        :fixed-layout="true"
                        :border-radius="20"
                        responsive-breakpoint="767px"
                        order-by="profit"
                        order="ASC"
                    >
                        <!-- Segment Header -->
                        <template #header.segment_label>
                            <div class="segment-label-column">
                                <span class="segment-header">Segment</span>
                            </div>
                        </template>
                        <!-- Segment -->
                        <template #column.segment_label>
                            <Text as="p" size="f-8" class="segment-label-column">
                                <span class="segment-label">
                                    <Skeleton :height="16" :width="Math.random() * 120 + 80" />
                                </span>
                            </Text>
                        </template>
                        <template #column.cpa>
                            <Skeleton :height="16" :width="32" />
                        </template>
                        <template #column.roas>
                            <Skeleton :height="16" :width="56" />
                        </template>
                        <!-- Target -->
                        <template #column.target>
                            <Skeleton :height="16" :width="56" />
                        </template>
                        <!-- Diff -->
                        <template #column.difference>
                            <Skeleton :height="16" :width="56" />
                        </template>
                    </oTable>
                </div>
            </div>
        </div>

        <!-- Segments Skeletons -->
        <div v-if="segmentsLoading">
            <div class="segment-section full-width" v-for="number in 4" :key="number">
                <SegmentContent />
            </div>
        </div>

        <!-- Segments -->
        <div
            v-if="allSegments"
            class="segment-section full-width"
            v-for="segmentKey in segmentKeys"
            :key="segmentKey"
            :id="segmentKey.toString()"
        >
            <SegmentContent
                :segment-key="segmentKey"
                :segments="allSegments[segmentKey]"
                :selected-group="selectedGroup"
            />
        </div>

        <!-- Empty State -->
        <div v-else-if="!segmentsLoading" class="segments-empty-state-container">
            <img src="@/assets/img/segments-empty-state.png" style="width: 220px" />
            <Spacer height="2rem" />
            <div class="center" style="max-width: 340px">
                <Text as="h5" size="f-5" weight="600" align="center">
                    No Segments data available
                </Text>
                <Spacer height="0.875rem" />
                <Text as="p" size="f-8" align="center">
                    Your segments data will appear here. You may have no conversion data in this
                    campaign group, consider selecting another from the menu at the
                    <a @click.stop="sidebarOpen = true">top right</a>.
                </Text>
            </div>
        </div>
    </PageContent>
</template>

<script lang="ts">
import {
    Text,
    Label,
    Spacer,
    oInput,
    oButton,
    LineChart,
    ExpandableSidebar,
    oTable,
    Percent,
    SegmentsChart,
    Spinner,
    Number,
    Roas,
    Tooltip,
} from '@opteo/components-next'

import Skeleton from '@/components/util/Skeleton.vue'
import Money from '@/components/global/oMoney.vue'
import { defineComponent } from 'vue'
import { useSegments } from '@/composition/performance/useSegments'
import PageContent from '@/layouts/PageContent.vue'
import SegmentContent from '@/components/Performance/SegmentContent.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { difference } from 'lodash'

export default defineComponent({
    name: 'PerformanceSegments',
    components: {
        Text,
        Label,
        Spacer,
        oInput,
        oButton,
        LineChart,
        ExpandableSidebar,
        Money,
        Roas,
        oTable,
        Percent,
        SegmentsChart,
        Spinner,
        Skeleton,
        PageContent,
        Number,
        SegmentContent,
        Tooltip,
    },

    setup() {
        return { ...useSegments(), ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// BestWorstSegments

.best-worst-container {
    @include container;
    width: 100%;
    @include flex;
    @include items-start;
    @include justify-center;
    @include ph-40;
    @include pv-36;
    @include mb-48;
    @include br-20;
}
.best-segments,
.worst-segments {
    // max-width: 463px;
    width: 100%;
}
.best-segments {
    margin-right: 2.125rem;
}
.segment-label-column {
    height: 64px;
    @include pointer;
    @include flex;
    @include items-center;
    @include relative;
}
.segment-label-column .segment-header {
    max-width: 100%;
    overflow: hidden;
    @include flex;
    @include items-center;
    height: 64px;
    @include pl-24;
}
.segment-label-column .segment-label {
    max-width: 100%;
    overflow: hidden;
    @include flex;
    @include items-center;
    height: 64px;
    @include pl-24;
}
.segment-label-column .segment-label::after {
    content: '';
    background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 6rem;
    bottom: 0;
}

// Segments

.segments-container {
    @include flex;
    @include w-100;
    gap: 2rem;
}

.segment-section {
    @include container;
    @include pa-32;
    @include mb-48;
    @include br-20;
}

.full-width {
    @include w-100;
}

.in-segment-name {
    @include o-30;
    @include opteo-black;
}

.segments-empty-state-container {
    @include flex;
    @include items-center;
    @include justify-center;
    @include tc;
    flex-direction: column;
    height: 30rem;
}

.in-table-empty-state-container {
    @include flex;
    @include items-center;
    @include justify-center;
    @include tc;
    flex-direction: column;
    height: 32.6875rem;
    @include container;
}

@media screen and (max-width: $mq-1279-max) {
    .segments-container,
    .best-worst-container {
        flex-direction: column;
        gap: unset;
    }
    .best-segments,
    .worst-segments {
        max-width: 100%;
    }

    .worst-segments {
        @include mt-48;
    }

    .segment-section {
        @include w-100;
    }
}
@media screen and (max-width: $mq-1024-max) {
    .best-worst-container {
        @include pa-32;
    }
}
@media screen and (max-width: $mq-767-max) {
    .best-worst-container {
        padding: unset;
        box-shadow: unset;
    }
    .segment-section {
        padding: unset;
        box-shadow: unset;
    }
    .segment-label-column {
        height: unset;
    }
    .segment-label-column .segment-header {
        height: unset;
        overflow: unset;
        display: unset;
        padding-left: unset;
    }
    .segment-label-column .segment-label {
        height: unset;
        overflow: unset;
        display: unset;
        padding-left: unset;
    }
    .segment-label-column .segment-label::after {
        background: unset;
        top: unset;
        right: unset;
        width: unset;
        bottom: unset;
    }
}
</style>
