<template>
    <Text as="h5" size="f-5" weight="600" style="line-height: 1.75rem">
        {{ alert.title }}
    </Text>
    <Spacer height="1.5rem" />
    <div v-if="isCampaignLevel">
        <EntityPillList
            v-if="campaign.length > 40"
            :items="[{ type: 'campaign', content: campaign }]"
        />
        <EntityPill v-else type="campaign" :content="campaign" />
        <Spacer height="1.5rem" />
        <Text as="p" size="f-8">
            Opteo detected an unexpected change in this campaign.
            <Spacer height="1rem" />
            Between
            <b>{{ formattedFromDate }}</b> and <b>{{ formattedToDate }}</b
            >, {{ metric }} {{ changeCopy }}d <b><Percent :value="percent" /></b>. This change could
            be due to seasonality or because of a recent adjustment you made. If something seems out
            of place, consider checking up on this campaign.
        </Text>
    </div>

    <div v-else>
        <Text as="p" size="f-8">
            Opteo detected an unexpected change in your account.
            <Spacer height="1rem" />
            Between
            <b>{{ formattedFromDate }}</b> and <b>{{ formattedToDate }}</b
            >, {{ metric }} {{ changeCopy }}d <b><Percent :value="percent" /></b>. This change could
            be due to seasonality or because of a recent adjustment you made. If something seems out
            of place, consider checking your account for the root cause.
        </Text>
    </div>
    <Spacer height="2rem" />
    <div class="chart-container">
        <LineChart
            :series="chartData"
            :metric="{ dataType: metric === 'cost' ? 'money' : 'number', currency: currencyCode }"
            :areas="redArea"
            :show-zero="true"
            :chart-height="180"
            disable-fallback
        />
    </div>
    <div v-if="!isCampaignLevel && responsibleCampaigns?.length > 0">
        <Spacer height="2rem" />
        <Text as="h5" size="f-5" weight="600">Responsible Campaigns</Text>
        <Spacer height="1.5rem" />
        <Text as="p" size="f-8">
            The following campaign<span v-if="campaignTableData.length > 1">s</span> experienced
            significant shifts between {{ formattedFromDate }} and {{ formattedToDate }} — this
            could be a contributing factor to the unexpected change.
        </Text>
        <Spacer />
        <oTable
            :headers="campaignTableHeaders"
            :items="campaignTableData"
            order-by="delta"
            order="DESC"
        >
            <template v-slot:[`column.from`]="from">
                <Money v-if="metric === 'cost'" :value="from.value" :currency="currencyCode" />
                <Number v-else :value="from.value" />
            </template>
            <template v-slot:[`column.to`]="to">
                <Money v-if="metric === 'cost'" :value="to.value" :currency="currencyCode" />
                <Number v-else :value="to.value" />
            </template>
            <template v-slot:[`column.delta`]="delta">
                <Text color="red">
                    <!-- Always red, because change is scary man -->
                    <Percent :value="delta.value" :include-sign="true" />
                </Text>
            </template>
        </oTable>
    </div>
    <Spacer height="2rem" />
    <AlertNotes :alert-date="isoDate" :account-id="alert?.accountId ?? ('' as Account.ID)" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import {
    Text,
    oTable,
    LineChart,
    Spacer,
    Money,
    Number,
    Percent,
    LineChartTypes,
    EntityPillList,
    EntityPill,
} from '@opteo/components-next'
import capitalize from 'lodash-es/capitalize'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import { Account, Alerts } from '@opteo/types'

import AlertNotes from '@/components/notes/AlertNotes.vue'
import Ent from '@/components/util/Ent.vue'
import { useAlert } from '@/composition/alerts/useAlert'

export default defineComponent({
    name: 'Delta',
    components: {
        AlertNotes,
        Text,
        oTable,
        LineChart,
        EntityPillList,
        EntityPill,
        Spacer,
        Money,
        Percent,
        Number,
        Ent,
    },
    setup() {
        const { alert, isCampaignLevel, currencyCode } = useAlert<
            Alerts.DeltaBody,
            Alerts.DeltaChartData
        >()

        if (!alert.value) {
            throw new Error('An alert ID must be set before initializing this alert.')
        }

        const isoDate = parseISO(alert.value.ts)

        const metric = alert.value.body.metric
        const percent = alert.value.body.percent / 100

        const values = alert.value.body.vals
        const isIncrease = values.prev < values.new

        const changeCopy = isIncrease ? 'increase' : 'decrease'
        const changeCampaignCopy = isIncrease ? 'unexpected increase' : 'unexpected decrease'
        // @ts-ignore This exists when the alert is campaign level
        const campaign = alert.value.body.campaign

        const metricCopy = computed(() => capitalize(metric))
        // @ts-ignore This exists when the alert is account level
        const responsibleCampaigns = computed(() => alert.value.body.responsible_campaigns)

        const fromDate = computed(() => parseISO(alert.value!.body.params.from))
        const toDate = computed(() => parseISO(alert.value!.body.params.to))

        const formattedFromDate = computed(() => format(fromDate.value, 'LLLL do'))
        const formattedToDate = computed(() => format(toDate.value, 'LLLL do'))

        const campaignTableHeaders = computed(() => [
            {
                key: 'campaign',
                text: 'Campaign',
            },
            {
                key: 'from',
                text: `${metricCopy.value} (${formattedFromDate.value})`,
            },
            {
                key: 'to',
                text: `${metricCopy.value} (${formattedToDate.value})`,
            },
            {
                key: 'delta',
                text: 'Delta (%)',
            },
        ])

        const campaignTableData = computed(() => {
            return responsibleCampaigns?.value.map((row: any) => {
                return {
                    id: row.campaign_id,
                    campaign: row.campaign,
                    from: row.from,
                    to: row.to,
                    delta: (row.from > row.to ? -row.percent : row.percent) / 100,
                }
            })
        })

        const chartData = computed<LineChartTypes.LineChartSeries[]>(() => {
            return [
                {
                    name: metricCopy.value,
                    items: alert.value?.chart.sorted_by_day_account_data.map(row => {
                        return {
                            x: new Date(row.day),
                            y: row[metric],
                        }
                    }),
                },
            ]
        })

        const redArea = computed(() => {
            return [{ axis: 'x', start: fromDate.value, end: toDate.value, color: 'red' }]
        })

        return {
            alert,
            isCampaignLevel,
            responsibleCampaigns,
            fromDate,
            formattedFromDate,
            toDate,
            formattedToDate,
            campaignTableHeaders,
            campaignTableData,
            metricCopy,
            metric,
            percent,
            campaign,
            chartData,
            changeCopy,
            changeCampaignCopy,
            isoDate,
            redArea,
            currencyCode,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.chart-container {
    @include container;
    @include pa-24;
}
</style>
