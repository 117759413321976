<template>
    <div>
        <div class="overview-switcher">
            <OverviewButton
                category="cost"
                key="cost"
                :amount="totalCostsSaved"
                :active="overviewMode === 'cost'"
                :improvementsPushed="costSavings.teamCostSavings.total.count"
                @clicked="changeOverviewMode"
                :period="period"
                :validating="$props.costSavingsValidating"
                :tooltipText="`For reference, your total Opteo subscription cost over this time period was <b>${subscriptionCost}</b>`"
            />
            <OverviewButton
                category="time"
                key="time"
                :amount="totalTimeSaved"
                :active="overviewMode === 'time'"
                @clicked="changeOverviewMode"
                :period="period"
                :validating="$props.timeSavingsValidating"
            />
            <OverviewButton
                category="alerts"
                key="alerts"
                :amount="totalAlertsFound"
                :active="overviewMode === 'alerts'"
                @clicked="changeOverviewMode"
                :period="period"
                :validating="$props.criticalAlertsValidating"
            />
        </div>
        <Spacer height="2rem" />
        <!-- Tables -->
        <oTable
            class="impact-statistics-overview-table"
            :headers="tableHeaders"
            :items="overviewTableData"
            :per-page="4"
            :border-radius="24"
            :orderBy="tableOrder"
            order="DESC"
            :key="tableOrder"
        >
            <!-- Improvement Type Header -->
            <template v-slot:header.improvementType>
                <div class="improvement-types-header-cell">
                    Improvement Type
                    <Tooltip
                        :maxWidth="296"
                        :offset="[0, 12]"
                        content="Please note, this table only includes Improvement types that have a specific Cost Saved value."
                    >
                        <InfoIcon class="improvement-types-info" />
                    </Tooltip>
                </div>
            </template>
            <!-- Detailed View Button -->
            <template v-slot:column.seeMore="seeMore">
                <oButton
                    color="white"
                    size="small"
                    class="see-more-button"
                    :disabled="seeMore.value.length < 1"
                    @clicked="openModal(seeMore.value)"
                >
                    Detailed View
                </oButton>
            </template>
            <!-- Skeleton for improvementType cells -->
            <template v-if="currentDataValidating" v-slot:column.improvementType>
                <Skeleton width="12rem" />
            </template>
            <!-- Skeleton for total time saved -->
            <template v-if="currentDataValidating" v-slot:column.totalTimeSaved>
                <Skeleton width="4.5rem" />
            </template>
            <!-- Skeleton for Count cells -->
            <template v-if="currentDataValidating" v-slot:column.count>
                <Skeleton width="2rem" />
            </template>
            <!-- Skeleton for time saved per imp-->
            <template v-if="currentDataValidating" v-slot:column.timeSavedPerImp>
                <Skeleton width="4.5rem" />
            </template>
            <!-- Skeleton for time saved per user-->
            <template v-if="currentDataValidating" v-slot:column.timeSavedPerUser>
                <Skeleton width="4.5rem" />
            </template>
            <!-- Skeleton for averagePerWeek -->
            <template v-if="currentDataValidating" v-slot:column.averagePerWeek>
                <Skeleton width="2.5rem" />
            </template>
            <!-- Skeleton for timeSavedPerAction -->
            <template v-if="currentDataValidating" v-slot:column.timeSavedPerAction>
                <Skeleton width="3.5rem" />
            </template>
            <!-- Skeleton for emailsSent -->
            <template v-if="currentDataValidating" v-slot:column.emailsSent>
                <Skeleton width="2rem" />
            </template>
            <!-- For sorting time correctly -->
            <template v-slot:column.totalTimeSaved="cell">
                {{ formatFromMinutes(cell.value, false) }}
            </template>
            <!-- Date formatting for mostRecent, or skeleton -->
            <template v-slot:column.mostRecent="cell">
                <Skeleton v-if="currentDataValidating" width="7rem" />
                <div v-else>{{ formatDateFromTimestamp(cell.value) }}</div>
            </template>
            <!-- Money cells where applicable -->
            <template v-slot:column.avgSavedPerImp="cell">
                <Money v-if="!currentDataValidating" currency="USD" :value="cell.value" />
                <Skeleton v-else-if="currentDataValidating" width="3rem" />
            </template>
            <template v-slot:column.totalCostSaved="cell">
                <Money v-if="!currentDataValidating" currency="USD" :value="cell.value" />
                <Skeleton v-else-if="currentDataValidating" width="3rem" />
            </template>
            <!--Footer-->
            <template #footer v-if="overviewMode === 'alerts'">
                <div class="impact-statistics-overview-table-footer">
                    <div class="impact-statistics-overview-table-footer-contents">
                        <div style="margin-right: 0.75rem">
                            <CheckIcon style="display: block" />
                        </div>
                        <div v-if="!currentDataValidating">
                            <Text size="f-8">
                                Opteo found <strong>{{ totalAlertsFound }} alerts</strong> across
                                <strong>{{ totalAccounts }} accounts</strong>.
                            </Text>
                        </div>
                        <div v-else-if="currentDataValidating">
                            <Skeleton width="400px" />
                        </div>
                    </div>
                </div>
            </template>
        </oTable>
        <!-- See More Modal -->
        <Modal
            v-model="seeMoreModalOpen"
            :title="modalTitle"
            width="78.5rem"
            @closed="resetNumberOfVisibleRows"
        >
            <template #content>
                <div v-if="overviewMode === 'cost'">
                    <ImpactStatisticsImprovementRow
                        v-for="(row, index) in slicedRows"
                        :improvement="row"
                        :class="index === slicedRows.length - 1 ? 'last' : ''"
                        @click="openInNewTab(row.open)"
                    />
                </div>
                <div v-if="overviewMode === 'alerts'">
                    <ImpactStatisticsAlertRow
                        v-for="(row, index) in slicedRows"
                        :alert="row"
                        :class="index === slicedRows.length - 1 ? 'last' : ''"
                    />
                </div>
                <div
                    v-if="modalTableRows.length > slicedRows.length"
                    class="show-more-button-wrapper"
                >
                    <oButton color="white" @clicked="showMoreRows">Show More</oButton>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script setup lang="ts">
// vue
import { computed, ref, PropType } from 'vue'
// frontend
import OverviewButton from '@/components/impactStatistics/OverviewButton.vue'
import useImpactStatistics from '@/composition/impactStatistics/useImpactStatistics'
import useImpactStatsTimeSavings from '@/composition/impactStatistics/useImpactStatsTimeSavings'
import ImpactStatisticsImprovementRow from '@/components/impactStatistics/ImpactStatisticsImprovementRow.vue'
import ImpactStatisticsAlertRow from '@/components/impactStatistics/ImpactStatisticsAlertRow.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import InfoIcon from '@/components/global/InfoIcon.vue'

// components-next
import {
    Text,
    Spacer,
    oTable,
    CheckIcon,
    oButton,
    Money,
    Modal,
    Tooltip,
    useMoney,
} from '@opteo/components-next'
// Types
import { IS } from '@opteo/types'
// lodash
import sortBy from 'lodash-es/sortBy'
import startCase from 'lodash-es/startCase'

// Props
const props = defineProps({
    costSavings: {
        type: Object as PropType<IS.GetCostSavedResponse>,
        required: true,
    },
    timeSavings: {
        type: Object as PropType<IS.GetTimeSavedResponse>,
        required: true,
    },
    criticalAlerts: {
        type: Object as PropType<IS.GetCriticalAlertCountsResponse>,
        required: true,
    },
    period: {
        type: String,
        required: true,
    },
    costSavingsValidating: {
        type: Boolean,
        required: true,
    },
    criticalAlertsValidating: {
        type: Boolean,
        required: true,
    },
    timeSavingsValidating: {
        type: Boolean,
        required: true,
    },
})
// useImpactStatistics
const {
    teamSize,
    getDomainInfoFromList,
    getAccountInfoFromList,
    formatDateFromTimestamp,
    calculateAveragePerWeek,
    openUrlInNewTab,
    generateImprovementRouterLink,
    generatePauseSpendRouterLink,
    generateAlertRouterLink,
} = useImpactStatistics()
// useImpactStatsTimeSavings functions
const { minutesToDuration, formatFromMinutes } = useImpactStatsTimeSavings()

const overviewMode = ref('cost')

const currentDataValidating = computed(() => {
    if (overviewMode.value === 'cost') return props.costSavingsValidating
    else if (overviewMode.value === 'time') return props.timeSavingsValidating
    else if (overviewMode.value === 'alerts') return props.criticalAlertsValidating
    else return false
})

const changeOverviewMode = (mode: string) => {
    // acceptable values
    if (mode === 'cost' || 'time' || 'alerts') overviewMode.value = mode
    else return
}

// Totals
const totalCostsSaved = computed(() => {
    return props.costSavings.teamCostSavings.total.monthlyCostSavingsUSD
})
const totalTimeSaved = computed(() => {
    return props.timeSavings.teamTimeSavings.totalMinutesSaved
})
const totalTimeSavedFooter = computed(() => {
    const duration = minutesToDuration(props.timeSavings.teamTimeSavings.totalMinutesSaved)
    // If minutes only or 0
    if (!duration.hours) return `${duration.minutes} ${'minutes'}`
    // If hours only
    else if (duration.hours && !duration.minutes) return `${duration.hours} ${'hours'}`
    // If hours + minutes
    else return `${duration.hours} hours and ${duration.minutes} minutes`
})
const totalAlertsFound = computed(() => {
    return props.criticalAlerts.teamCriticalAlertCount.totalCriticalAlertCount
})
const totalActions = computed(() => {
    return (
        props.timeSavings.teamTimeSavings.biddingExperimentsCount +
        props.timeSavings.teamTimeSavings.updatedRsaCount +
        props.timeSavings.teamTimeSavings.improvementsCount +
        props.timeSavings.teamTimeSavings.reportsCount
    )
})
const totalAccounts = computed(() => {
    return Object.keys(props.criticalAlerts.domainCriticalAlertCounts).length
})

const subscriptionCost = computed(() => {
    return useMoney({ value: props.costSavings.subscriptionCost, currency: 'USD' }).displayValue
        .value
})

// util
const calculateAvgSavedPerImp = ({
    impCount,
    costSavings,
}: {
    impCount: number
    costSavings: number
}) => {
    if (!impCount || !costSavings) return 0
    else return costSavings / impCount
}

// Table data
const costHeaders = [
    { key: 'improvementType', text: 'Improvement Type' },
    { key: 'totalCostSaved', text: 'Cost Saved', sortable: true },
    { key: 'count', text: 'Count', sortable: true },
    { key: 'avgSavedPerImp', text: 'Per Imp.', sortable: true },
    { key: 'seeMore', text: 'Actions' },
]
const timeHeaders = [
    { key: 'actionType', text: 'Action Type' },
    { key: 'totalTimeSaved', text: 'Time Saved', sortable: true },
    { key: 'count', text: 'Count', sortable: true },
    { key: 'timeSavedPerAction', text: 'Per Action', sortable: true },
    { key: 'mostRecent', text: 'Most Recent', sortable: true },
]
const alertHeaders = [
    { key: 'alertType', text: 'Alert Type' },
    { key: 'count', text: 'Count', sortable: true },
    { key: 'emailsSent', text: 'Emails Sent', sortable: true },
    { key: 'mostRecent', text: 'Most Recent', sortable: true },
    { key: 'seeMore', text: 'Actions' },
]
const tableHeaders = computed(() => {
    if (overviewMode.value === 'cost') return costHeaders
    else if (overviewMode.value === 'time') return timeHeaders
    else if (overviewMode.value === 'alerts') return alertHeaders
    else
        throw new Error(
            `Invalid overviewMode value: ${overviewMode.value}. Should be 'cost', 'time', or 'alerts'.`
        )
})
const tableOrder = computed(() => {
    if (overviewMode.value === 'cost') return 'totalCostSaved'
    else if (overviewMode.value === 'time') return 'totalTimeSaved'
    else if (overviewMode.value === 'alerts') return 'count'
})

type AllAlertInstances = {
    budget: IS.CriticalAlert[]
    delta: IS.CriticalAlert[]
    brokenLink: IS.CriticalImprovementAlert[]
    flatline: IS.CriticalAlert[]
}

const alertInstances = computed(() => {
    const allInstances: AllAlertInstances = {
        budget: [],
        delta: [],
        brokenLink: [],
        flatline: [],
    }
    for (const value of Object.values(props.criticalAlerts.domainCriticalAlertCounts)) {
        const instances = value.instances
        // handle broken link imps
        for (let i = 0; i < instances.improvements.length; i++) {
            allInstances.brokenLink.push(instances.improvements[i])
        }
        // handle alerts
        for (let i = 0; i < instances.alerts.length; i++) {
            const alertType: string = instances.alerts[i].type

            if (alertType === 'conversion') {
                // excluding conversion alerts
                continue
            } else if (alertType === 'budget') {
                allInstances.budget.push(instances.alerts[i])
            } else if (alertType === 'delta') {
                allInstances.delta.push(instances.alerts[i])
            } else if (alertType === 'flatline') {
                allInstances.flatline.push(instances.alerts[i])
            }
        }
    }
    // Sort alert instances by date
    allInstances.budget = sortBy(allInstances.budget, ['ts']).reverse()
    allInstances.delta = sortBy(allInstances.delta, ['ts']).reverse()
    allInstances.brokenLink = sortBy(allInstances.brokenLink, ['ts']).reverse()
    allInstances.flatline = sortBy(allInstances.flatline, ['ts']).reverse()

    return allInstances
})

const mostRecentBudgetAlert = computed(() => {
    if (alertInstances.value.budget.length < 1 || !alertInstances.value.budget) {
        return 'N/A'
    }

    return alertInstances.value.budget.reduce((prev, current) =>
        prev.ts > current.ts ? prev : current
    ).ts
})

const dateOfFirstBudgetAlert = computed<string | Date>(() => {
    if (alertInstances.value.budget.length < 1 || !alertInstances.value.budget) {
        return 'N/A'
    }
    return alertInstances.value.budget.reduce((prev, current) =>
        prev.ts > current.ts ? current : prev
    ).ts
})

const mostRecentFlatlineAlert = computed(() => {
    if (alertInstances.value.flatline.length < 1 || !alertInstances.value.flatline) {
        return 'N/A'
    }

    return alertInstances.value.flatline.reduce((prev, current) =>
        prev.ts > current.ts ? prev : current
    ).ts
})

const dateOfFirstFlatlineAlert = computed(() => {
    if (alertInstances.value.flatline.length < 1 || !alertInstances.value.flatline) {
        return 'N/A'
    }
    return alertInstances.value.flatline.reduce((prev, current) =>
        prev.ts > current.ts ? current : prev
    ).ts
})

const mostRecentDeltaAlert = computed(() => {
    if (alertInstances.value.delta.length < 1 || !alertInstances.value.delta) {
        return 'N/A'
    }

    return alertInstances.value.delta.reduce((prev, current) =>
        prev.ts > current.ts ? prev : current
    ).ts
})

const dateOfFirstDeltaAlert = computed(() => {
    if (alertInstances.value.delta.length < 1 || !alertInstances.value.delta) {
        return 'N/A'
    }
    return alertInstances.value.delta.reduce((prev, current) =>
        prev.ts > current.ts ? current : prev
    ).ts
})

const mostRecentBrokenLink = computed(() => {
    if (alertInstances.value.brokenLink.length < 1 || !alertInstances.value.brokenLink) {
        return 'N/A'
    }

    return alertInstances.value.brokenLink.reduce((prev, current) =>
        prev.ts > current.ts ? prev : current
    ).ts
})

const dateOfFirstBrokenLink = computed<string | Date>(() => {
    if (alertInstances.value.brokenLink.length < 1 || !alertInstances.value.brokenLink) {
        return 'N/A'
    }
    return alertInstances.value.brokenLink.reduce((prev, current) =>
        prev.ts > current.ts ? current : prev
    ).ts
})

type AllCostSavingsInstances = {
    pauseSpend: IS.PauseSpendCostSavings[]
    add_frequency_capping: IS.ImprovementCostSavings[]
    add_negative_geo_keyword: IS.ImprovementCostSavings[]
    add_negative_ngram: IS.ImprovementCostSavings[]
    add_robot_negative: IS.ImprovementCostSavings[]
    adjust_keyword_bid_v2: IS.ImprovementCostSavings[]
    adjust_location_bids: IS.ImprovementCostSavings[]
    check_query_relevance: IS.ImprovementCostSavings[]
    disable_interest_locations_v2: IS.ImprovementCostSavings[]
    disable_search_partners: IS.ImprovementCostSavings[]
    exclude_content: IS.ImprovementCostSavings[]
    exclude_location: IS.ImprovementCostSavings[]
    exclude_single_demographic: IS.ImprovementCostSavings[]
    exclude_single_device: IS.ImprovementCostSavings[]
    fix_broken_link: IS.ImprovementCostSavings[]
    pause_ad_v2: IS.ImprovementCostSavings[]
    pause_duplicate_keywords_v2: IS.ImprovementCostSavings[]
    pause_keyword_v2: IS.ImprovementCostSavings[]
    reduce_campaign_bids: IS.ImprovementCostSavings[]
    reduce_ngram_bids: IS.ImprovementCostSavings[]
    remove_broad_keywords_v2: IS.ImprovementCostSavings[]
}

const costSavingsWithInstances = computed(() => {
    // All rec_actions considered in luckymetrics calculation for cost savings
    // Could be good to get those names from the backend to ensure they always correspond to the names of the rec_actions
    const allInstances: AllCostSavingsInstances = {
        pauseSpend: [],
        add_frequency_capping: [],
        add_negative_geo_keyword: [],
        add_negative_ngram: [],
        add_robot_negative: [],
        adjust_keyword_bid_v2: [],
        adjust_location_bids: [],
        check_query_relevance: [],
        disable_interest_locations_v2: [],
        disable_search_partners: [],
        exclude_content: [],
        exclude_location: [],
        exclude_single_demographic: [],
        exclude_single_device: [],
        fix_broken_link: [],
        pause_ad_v2: [],
        pause_duplicate_keywords_v2: [],
        pause_keyword_v2: [],
        reduce_campaign_bids: [],
        reduce_ngram_bids: [],
        remove_broad_keywords_v2: [],
    }

    const pauseSpendTotalSavings = {
        totalMonthlySavingsGBP: 0,
        totalMonthlySavingsUSD: 0,
        totalYearlySavingsGBP: 0,
        totalYearlySavingsUSD: 0,
    }

    // Loop over domains and distribute instances; would there be a way to avoid nested looping for performance?
    for (const [key, value] of Object.entries(props.costSavings.domainCostSavings)) {
        // handle pause spend
        if (value.pauseSpend.hasBudgetAutoPause || value.pauseSpend.monthlyCostSavingsUSD > 0) {
            const instanceWithDomainId = {
                domainId: +key,
                ...value.pauseSpend,
            }
            allInstances.pauseSpend.push(instanceWithDomainId) // Add domain info later
            // Update totals
            pauseSpendTotalSavings.totalMonthlySavingsGBP += value.pauseSpend.monthlyCostSavingsGBP
            pauseSpendTotalSavings.totalMonthlySavingsUSD += value.pauseSpend.monthlyCostSavingsUSD
            pauseSpendTotalSavings.totalYearlySavingsGBP += value.pauseSpend.yearlyCostSavingsGBP
            pauseSpendTotalSavings.totalYearlySavingsUSD += value.pauseSpend.yearlyCostSavingsUSD
        }
        // handle improvements
        for (let i = 0; i < value.improvements.instances.length; i++) {
            const instance = value.improvements.instances[i]
            if (allInstances.hasOwnProperty(instance.recAction)) {
                allInstances[
                    instance.recAction as keyof Omit<AllCostSavingsInstances, 'pauseSpend'>
                ].push(instance)
            }
        }
    }

    // Format data for display in overview table
    return [
        {
            improvementType: 'Exclude Placement',
            count: allInstances.exclude_content.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.exclude_content.length,
                costSavings:
                    allInstances.exclude_content.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions.exclude_content !=
                        undefined
                        ? props.costSavings.teamCostSavings.improvements.recActions.exclude_content
                              .monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.exclude_content.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.exclude_content !=
                    undefined
                    ? props.costSavings.teamCostSavings.improvements.recActions.exclude_content
                          .monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(5 * allInstances.exclude_content.length, false),
            seeMore: allInstances.exclude_content,
        },
        {
            improvementType: 'Reduce N-Gram Bids',
            count: allInstances.reduce_ngram_bids.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.reduce_ngram_bids.length,
                costSavings:
                    allInstances.reduce_ngram_bids.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions.reduce_ngram_bids
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .reduce_ngram_bids.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.reduce_ngram_bids.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.reduce_ngram_bids
                    ? props.costSavings.teamCostSavings.improvements.recActions.reduce_ngram_bids
                          .monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(5 * allInstances.reduce_ngram_bids.length, false),
            seeMore: allInstances.reduce_ngram_bids,
        },
        {
            improvementType: 'Add N-Gram Negative',
            count: allInstances.add_negative_ngram.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.add_negative_ngram.length,
                costSavings:
                    allInstances.add_negative_ngram.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions.add_negative_ngram
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .add_negative_ngram.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.add_negative_ngram.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.add_negative_ngram
                    ? props.costSavings.teamCostSavings.improvements.recActions.add_negative_ngram
                          .monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(5 * allInstances.add_negative_ngram.length, false),
            seeMore: allInstances.add_negative_ngram,
        },
        {
            improvementType: 'Disable Search Partner Sites',
            count: allInstances.disable_search_partners.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.disable_search_partners.length,
                costSavings:
                    allInstances.disable_search_partners.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions
                        .disable_search_partners
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .disable_search_partners.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.disable_search_partners.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.disable_search_partners
                    ? props.costSavings.teamCostSavings.improvements.recActions
                          .disable_search_partners.monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(
                5 * allInstances.disable_search_partners.length,
                false
            ),
            seeMore: allInstances.disable_search_partners,
        },
        {
            improvementType: 'Adjust Location Bids',
            count: allInstances.adjust_location_bids.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.adjust_location_bids.length,
                costSavings:
                    allInstances.adjust_location_bids.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions.adjust_location_bids
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .adjust_location_bids.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.adjust_location_bids.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.adjust_location_bids
                    ? props.costSavings.teamCostSavings.improvements.recActions.adjust_location_bids
                          .monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(5 * allInstances.adjust_location_bids.length, false),
            seeMore: allInstances.adjust_location_bids,
        },
        {
            improvementType: 'Adjust Keyword Bid',
            count: allInstances.adjust_keyword_bid_v2.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.adjust_keyword_bid_v2.length,
                costSavings:
                    allInstances.adjust_keyword_bid_v2.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions.adjust_keyword_bid_v2
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .adjust_keyword_bid_v2.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.adjust_keyword_bid_v2.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.adjust_keyword_bid_v2
                    ? props.costSavings.teamCostSavings.improvements.recActions
                          .adjust_keyword_bid_v2.monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(5 * allInstances.adjust_keyword_bid_v2.length, false),
            seeMore: allInstances.adjust_keyword_bid_v2,
        },
        {
            improvementType: 'Pause Keyword',
            count: allInstances.pause_keyword_v2.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.pause_keyword_v2.length,
                costSavings:
                    allInstances.pause_keyword_v2.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions.pause_keyword_v2
                        ? props.costSavings.teamCostSavings.improvements.recActions.pause_keyword_v2
                              .monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.pause_keyword_v2.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.pause_keyword_v2
                    ? props.costSavings.teamCostSavings.improvements.recActions.pause_keyword_v2
                          .monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(5 * allInstances.pause_keyword_v2.length, false),
            seeMore: allInstances.pause_keyword_v2,
        },
        {
            improvementType: 'Replace Broad Keyword',
            count: allInstances.remove_broad_keywords_v2.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.remove_broad_keywords_v2.length,
                costSavings:
                    allInstances.remove_broad_keywords_v2.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions
                        .remove_broad_keywords_v2
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .remove_broad_keywords_v2.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.remove_broad_keywords_v2.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.remove_broad_keywords_v2
                    ? props.costSavings.teamCostSavings.improvements.recActions
                          .remove_broad_keywords_v2.monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(
                5 * allInstances.remove_broad_keywords_v2.length,
                false
            ),
            seeMore: allInstances.remove_broad_keywords_v2,
        },
        {
            improvementType: 'Disable Locations of Interest',
            count: allInstances.disable_interest_locations_v2.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.disable_interest_locations_v2.length,
                costSavings:
                    allInstances.disable_interest_locations_v2.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions
                        .disable_interest_locations_v2
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .disable_interest_locations_v2.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.disable_interest_locations_v2.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions
                    .disable_interest_locations_v2
                    ? props.costSavings.teamCostSavings.improvements.recActions
                          .disable_interest_locations_v2.monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(
                5 * allInstances.disable_interest_locations_v2.length,
                false
            ),
            seeMore: allInstances.disable_interest_locations_v2,
        },
        {
            improvementType: 'Pause Ad',
            count: allInstances.pause_ad_v2.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.pause_ad_v2.length,
                costSavings:
                    allInstances.pause_ad_v2.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions.pause_ad_v2
                        ? props.costSavings.teamCostSavings.improvements.recActions.pause_ad_v2
                              .monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.pause_ad_v2.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.pause_ad_v2
                    ? props.costSavings.teamCostSavings.improvements.recActions.pause_ad_v2
                          .monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(5 * allInstances.pause_ad_v2.length, false),
            seeMore: allInstances.pause_ad_v2,
        },
        {
            improvementType: 'Pause Duplicate Keywords',
            count: allInstances.pause_duplicate_keywords_v2.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.pause_duplicate_keywords_v2.length,
                costSavings:
                    allInstances.pause_duplicate_keywords_v2.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions
                        .pause_duplicate_keywords_v2
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .pause_duplicate_keywords_v2.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.pause_duplicate_keywords_v2.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions
                    .pause_duplicate_keywords_v2
                    ? props.costSavings.teamCostSavings.improvements.recActions
                          .pause_duplicate_keywords_v2.monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(
                5 * allInstances.pause_duplicate_keywords_v2.length,
                false
            ),
            seeMore: allInstances.pause_duplicate_keywords_v2,
        },
        {
            improvementType: 'Pause Spend When Over Budget',
            count: allInstances.pauseSpend.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.pauseSpend.length,
                costSavings: pauseSpendTotalSavings.totalMonthlySavingsUSD,
            }),
            totalCostSaved: pauseSpendTotalSavings.totalMonthlySavingsUSD,
            timeSavedPerImp: 'N/A',
            totalTimeSaved: 'N/A',
            seeMore: allInstances.pauseSpend,
        },
        {
            improvementType: 'Reduce Campaign Bids',
            count: allInstances.reduce_campaign_bids.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.reduce_campaign_bids.length,
                costSavings:
                    allInstances.reduce_campaign_bids.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions.reduce_campaign_bids
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .reduce_campaign_bids.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.reduce_campaign_bids.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.reduce_campaign_bids
                    ? props.costSavings.teamCostSavings.improvements.recActions.reduce_campaign_bids
                          .monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(5 * allInstances.reduce_campaign_bids.length, false),
            seeMore: allInstances.reduce_campaign_bids,
        },
        {
            improvementType: 'Add Location Name as Negative',
            count: allInstances.add_negative_geo_keyword.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.add_negative_geo_keyword.length,
                costSavings:
                    allInstances.add_negative_geo_keyword.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions
                        .add_negative_geo_keyword
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .add_negative_geo_keyword.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.add_negative_geo_keyword.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.add_negative_geo_keyword
                    ? props.costSavings.teamCostSavings.improvements.recActions
                          .add_negative_geo_keyword.monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(
                5 * allInstances.add_negative_geo_keyword.length,
                false
            ),
            seeMore: allInstances.add_negative_geo_keyword,
        },
        {
            improvementType: 'Add New Keyword',
            count: allInstances.check_query_relevance.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.check_query_relevance.length,
                costSavings:
                    allInstances.check_query_relevance.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions.check_query_relevance
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .check_query_relevance.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.check_query_relevance.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.check_query_relevance
                    ? props.costSavings.teamCostSavings.improvements.recActions
                          .check_query_relevance.monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(5 * allInstances.check_query_relevance.length, false),
            seeMore: allInstances.check_query_relevance,
        },
        {
            improvementType: 'Fix Broken Link',
            count: allInstances.fix_broken_link.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.fix_broken_link.length,
                costSavings:
                    allInstances.fix_broken_link.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions.fix_broken_link
                        ? props.costSavings.teamCostSavings.improvements.recActions.fix_broken_link
                              .monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.fix_broken_link.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.fix_broken_link
                    ? props.costSavings.teamCostSavings.improvements.recActions.fix_broken_link
                          .monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(5 * allInstances.fix_broken_link.length, false),
            seeMore: allInstances.fix_broken_link,
        },
        {
            improvementType: 'Add Frequency Cap',
            count: allInstances.add_frequency_capping.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.add_frequency_capping.length,
                costSavings:
                    allInstances.add_frequency_capping.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions.add_frequency_capping
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .add_frequency_capping.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.add_frequency_capping.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.add_frequency_capping
                    ? props.costSavings.teamCostSavings.improvements.recActions
                          .add_frequency_capping.monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(5 * allInstances.add_frequency_capping.length, false),
            seeMore: allInstances.add_frequency_capping,
        },
        {
            improvementType: 'Exclude Location',
            count: allInstances.exclude_location.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.exclude_location.length,
                costSavings:
                    allInstances.exclude_location.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions.exclude_location
                        ? props.costSavings.teamCostSavings.improvements.recActions.exclude_location
                              .monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.exclude_location.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.exclude_location
                    ? props.costSavings.teamCostSavings.improvements.recActions.exclude_location
                          .monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(5 * allInstances.exclude_location.length, false),
            seeMore: allInstances.exclude_location,
        },
        {
            improvementType: 'Exclude Demographic',
            count: allInstances.exclude_single_demographic.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.exclude_single_demographic.length,
                costSavings:
                    allInstances.exclude_single_demographic.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions
                        .exclude_single_demographic
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .exclude_single_demographic.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.exclude_single_demographic.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.exclude_single_demographic
                    ? props.costSavings.teamCostSavings.improvements.recActions
                          .exclude_single_demographic.monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(
                5 * allInstances.exclude_single_demographic.length,
                false
            ),
            seeMore: allInstances.exclude_single_demographic,
        },
        {
            improvementType: 'Exclude Device',
            count: allInstances.exclude_single_device.length,
            avgSavedPerImp: calculateAvgSavedPerImp({
                impCount: allInstances.exclude_single_device.length,
                costSavings:
                    allInstances.exclude_single_device.length > 0 &&
                    props.costSavings.teamCostSavings.improvements.recActions.exclude_single_device
                        ? props.costSavings.teamCostSavings.improvements.recActions
                              .exclude_single_device.monthlyCostSavingsUSD
                        : 0,
            }),
            totalCostSaved:
                allInstances.exclude_single_device.length > 0 &&
                props.costSavings.teamCostSavings.improvements.recActions.exclude_single_device
                    ? props.costSavings.teamCostSavings.improvements.recActions
                          .exclude_single_device.monthlyCostSavingsUSD
                    : 0,
            timeSavedPerImp: '5 minutes',
            totalTimeSaved: formatFromMinutes(5 * allInstances.exclude_single_device.length, false),
            seeMore: allInstances.exclude_single_device,
        },
    ]
})

const overviewTableData = computed(() => {
    // cost
    if (overviewMode.value === 'cost') {
        // Sorted in descending order according to total cost savings
        return sortBy(costSavingsWithInstances.value, ['totalCostSaved']).reverse()
    }
    // time
    else if (overviewMode.value === 'time') {
        return [
            {
                actionType: 'Improvements Completed',
                count: props.timeSavings.teamTimeSavings.improvementsCount,
                timeSavedPerAction: '5 minutes',
                timeSavedPerUser: formatFromMinutes(
                    Math.floor(
                        props.timeSavings.teamTimeSavings.improvementsMinutesSaved / teamSize.value
                    ),
                    false
                ), // Is that the intended calculation?
                mostRecent: props.timeSavings.teamTimeSavings.mostRecentImprovement,
                totalTimeSaved: props.timeSavings.teamTimeSavings.improvementsMinutesSaved,
            },
            {
                actionType: 'Reports Created',
                count: props.timeSavings.teamTimeSavings.reportsCount,
                timeSavedPerAction: '1 hour',
                timeSavedPerUser: formatFromMinutes(
                    Math.floor(
                        props.timeSavings.teamTimeSavings.reportsMinutesSaved / teamSize.value
                    ),
                    false
                ),
                mostRecent: props.timeSavings.teamTimeSavings.mostRecentReport,
                totalTimeSaved: props.timeSavings.teamTimeSavings.reportsMinutesSaved,
            },
            {
                actionType: 'RSAs Created',
                count: props.timeSavings.teamTimeSavings.createdRsaCount,
                timeSavedPerAction: '15 minutes',
                timeSavedPerUser: formatFromMinutes(
                    Math.floor(
                        props.timeSavings.teamTimeSavings.createdRsaMinutesSaved / teamSize.value
                    ),
                    false
                ),
                mostRecent: props.timeSavings.teamTimeSavings.mostRecentCreatedRsa,
                totalTimeSaved: props.timeSavings.teamTimeSavings.createdRsaMinutesSaved,
            },
            {
                actionType: 'RSAs Updated',
                count: props.timeSavings.teamTimeSavings.updatedRsaCount,
                timeSavedPerAction: '10 minutes',
                timeSavedPerUser: formatFromMinutes(
                    Math.floor(
                        props.timeSavings.teamTimeSavings.updatedRsaMinutesSaved / teamSize.value
                    ),
                    false
                ),
                mostRecent: props.timeSavings.teamTimeSavings.mostRecentUpdatedRsa,
                totalTimeSaved: props.timeSavings.teamTimeSavings.updatedRsaMinutesSaved,
            },
            {
                actionType: 'Bidding Experiments Started',
                count: props.timeSavings.teamTimeSavings.biddingExperimentsCount,
                timeSavedPerAction: '20 minutes',
                timeSavedPerUser: formatFromMinutes(
                    Math.floor(
                        props.timeSavings.teamTimeSavings.biddingExperimentsMinutesSaved /
                            teamSize.value
                    ),
                    false
                ),
                mostRecent: '2022-08-20T15:30:45.000Z', // needsData
                totalTimeSaved: props.timeSavings.teamTimeSavings.biddingExperimentsMinutesSaved,
            },
            {
                actionType: 'Scorecards Generated',
                count: props.timeSavings.teamTimeSavings.scorecardsCount,
                timeSavedPerAction: '1 hour',
                timeSavedPerUser: formatFromMinutes(
                    Math.floor(
                        props.timeSavings.teamTimeSavings.scorecardsMinutesSaved / teamSize.value
                    ),
                    false
                ),
                mostRecent: props.timeSavings.teamTimeSavings.mostRecentScorecard,
                totalTimeSaved: props.timeSavings.teamTimeSavings.scorecardsMinutesSaved,
            },
        ]
    }
    // alerts
    else if (overviewMode.value === 'alerts') {
        return [
            {
                alertType: 'Budget Alerts',
                count: props.criticalAlerts.teamCriticalAlertCount.budgetAlertCount,
                averagePerWeek: calculateAveragePerWeek({
                    dateOfFirstInstance: dateOfFirstBudgetAlert.value,
                    count: props.criticalAlerts.teamCriticalAlertCount.budgetAlertCount,
                }), // Deal with this once I figure out date range
                mostRecent: mostRecentBudgetAlert.value,
                emailsSent: props.criticalAlerts.teamCriticalAlertCount.budgetAlertCount * 3, // needsData (Consider multiplying `count` by `teamSize`)
                seeMore: alertInstances.value.budget, // Depends on forming the same arrays as needed for most recent
            },
            {
                alertType: 'Unexpected Metric Change',
                count: props.criticalAlerts.teamCriticalAlertCount.deltaAlertCount,
                averagePerWeek: calculateAveragePerWeek({
                    dateOfFirstInstance: dateOfFirstDeltaAlert.value,
                    count: props.criticalAlerts.teamCriticalAlertCount.deltaAlertCount,
                }),
                mostRecent: mostRecentDeltaAlert.value,
                emailsSent: props.criticalAlerts.teamCriticalAlertCount.deltaAlertCount * 3, // needsData (Consider multiplying `count` by `teamSize`)
                seeMore: alertInstances.value.delta,
            },
            {
                alertType: 'Broken Link or Sitelink',
                count: props.criticalAlerts.teamCriticalAlertCount.brokenLinkImprovementCount,
                averagePerWeek: calculateAveragePerWeek({
                    dateOfFirstInstance: dateOfFirstBrokenLink.value,
                    count: props.criticalAlerts.teamCriticalAlertCount.brokenLinkImprovementCount,
                }),
                mostRecent: mostRecentBrokenLink.value,
                emailsSent:
                    props.criticalAlerts.teamCriticalAlertCount.brokenLinkImprovementCount * 3, // needsData (Consider multiplying `count` by `teamSize`)
                seeMore: alertInstances.value.brokenLink,
            },
            {
                alertType: 'Zero Impressions',
                count: props.criticalAlerts.teamCriticalAlertCount.flatlineAlertCount,
                averagePerWeek: calculateAveragePerWeek({
                    dateOfFirstInstance: dateOfFirstFlatlineAlert.value,
                    count: props.criticalAlerts.teamCriticalAlertCount.flatlineAlertCount,
                }),
                mostRecent: mostRecentFlatlineAlert.value,
                emailsSent: props.criticalAlerts.teamCriticalAlertCount.flatlineAlertCount * 3, // needsData (Consider multiplying `count` by `teamSize`)
                seeMore: alertInstances.value.flatline,
            },
        ]
    } else
        throw new Error(
            `Invalid overviewMode value: ${overviewMode.value}. Should be 'cost', 'time', or 'alerts'.`
        )
})
// Modal
const seeMoreModalOpen = ref(false)
const modalTitle = ref('Breakdown')
const modalTableHeaders = computed(() => {
    if (overviewMode.value === 'cost') {
        return [
            { key: 'account', text: 'Account', noPadding: true },
            { key: 'title', text: 'Title' },
            { key: 'location', text: 'Location' },
            { key: 'savings', text: 'Savings', sortable: true },
            { key: 'date', text: 'Date completed', sortable: true },
            { key: 'open', text: 'Open' },
        ]
    } else if (overviewMode.value === 'alerts') {
        return [
            { key: 'account', text: 'Account', noPadding: true },
            { key: 'title', text: 'Title' },
            { key: 'date', text: 'Date', sortable: true },
            //{ key: 'open', text: 'Open' },  // removed
        ]
    } else return []
})

interface ModalRow {
    account: object
    title: string
    open: object
}

const modalTableRows = ref<ModalRow[]>([])
const generateModalRows = (instances: any[]) => {
    // Costs
    if (overviewMode.value === 'cost') {
        // Generate rows
        const rows = instances.map(instance => {
            // Pause spend
            if (instance.hasOwnProperty('hasBudgetAutoPause')) {
                return {
                    account: getAccountInfoFromList(instance.accountId),
                    title: 'Pause Spend When Over Budget',
                    location: [{ entity: 'account-level', label: 'Account Level' }],
                    savings: instance.monthlyCostSavingsUSD,
                    hasBudgetAutoPause: instance.hasBudgetAutoPause,
                    date: '', // figure out what to do because there is none
                    open: { domainId: instance.accountId, improvementId: 'pauseSpend' },
                }
            }
            // Improvements
            else {
                console.log(JSON.stringify(instance))
                return {
                    account: getAccountInfoFromList(instance.accountId), // for now, retrieve account info later
                    title: instance.title,
                    location: instance.location,
                    savings: instance.monthlyCostSavingsUSD,
                    date: formatDateFromTimestamp(instance.ts),
                    open: { domainId: instance.accountId, improvementId: instance.improvementId },
                }
            }
        })
        // Sort generated rows and set them as modalRows value
        modalTableRows.value = sortBy(rows, ['savings']).reverse()
    }
    // Alerts
    else if (overviewMode.value === 'alerts') {
        const rows = instances.map(instance => {
            // Broken Link Improvements
            if (instance.hasOwnProperty('improvementId')) {
                return {
                    account: getDomainInfoFromList(instance.domainId),
                    title: startCase(instance.recAction.replaceAll('_', ' ')),
                    ts: instance.ts,
                    date: formatDateFromTimestamp(instance.ts),
                    open: { domainId: instance.domainId, improvementId: instance.improvementId },
                }
            }
            // Alerts
            else {
                return {
                    account: getDomainInfoFromList(instance.domainId),
                    title: instance.title,
                    ts: instance.ts,
                    date: formatDateFromTimestamp(instance.ts),
                    open: { hash_alert_id: instance.hash_alert_id },
                }
            }
        })
        modalTableRows.value = sortBy(rows, ['ts']).reverse()
    } else throw new Error('overviewMode not valid, should be either cost or alerts')
}
const openInNewTab = (instanceData: any) => {
    // Pause Spend
    if (instanceData.improvementId === 'pauseSpend') {
        openUrlInNewTab(generatePauseSpendRouterLink({ domainId: instanceData.domainId }))
    }
    // Improvements
    else if (instanceData.improvementId) {
        openUrlInNewTab(
            generateImprovementRouterLink({
                domainId: instanceData.domainId,
                improvementId: instanceData.improvementId,
            })
        )
    }
    // Alerts
    else if (instanceData.hash_alert_id) {
        openUrlInNewTab(generateAlertRouterLink(instanceData.hash_alert_id))
    }
}
const openModal = (instances: any[]) => {
    // Set the correct modal title
    if (overviewMode.value === 'cost') {
        modalTitle.value = 'Cost Savings Breakdown'
    } else if (overviewMode.value === 'alerts') {
        modalTitle.value = 'Critical Alerts Breakdown'
    }
    // Generate table cells from data
    generateModalRows(instances)
    // Open the modal
    seeMoreModalOpen.value = true
}

const numberOfVisibleRows = ref(50)
const slicedRows = computed(() => {
    return modalTableRows.value.slice(0, numberOfVisibleRows.value)
})
const showMoreRows = () => {
    numberOfVisibleRows.value = numberOfVisibleRows.value + 50
}
const resetNumberOfVisibleRows = () => {
    numberOfVisibleRows.value = 50
}

const determineMostRecentRsa = (
    updatedTs: string | Date | null,
    createdTs: string | Date | null
) => {
    if (!updatedTs && !createdTs) return 'N/A'
    else if (!updatedTs) return createdTs
    else if (!createdTs) return updatedTs
    else {
        const updatedDate = new Date(updatedTs)
        const createdDate = new Date(createdTs)
        return updatedDate > createdDate ? updatedTs : createdTs
    }
}
</script>

<!-- Scoped -->
<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.overview-switcher {
    @include flex;
    gap: 2rem;
}

.more-dots-icon-container {
    @include flex;
    width: 100%;
    justify-content: center;
}

.improvement-types-header-cell {
    @include flex;
    @include items-center;
}
.improvement-types-info {
    width: 0.75rem;
    height: 0.75rem;
    margin-left: 0.4375rem;
    @include block;
}

.impact-statistics-overview-table-footer {
    width: 100%;
    @include ph-24;
    @include pv-16;
    border-top: 1px solid;
    @include opteo-border;
    .impact-statistics-overview-table-footer-contents {
        display: flex;
        align-items: center;
        height: 32px;
    }
}
// Modal
.show-more-button-wrapper {
    width: 100%;
    @include flex-center;
    @include mt-24;
}
</style>

<!-- Unscoped -->
<style lang="scss">
@media screen and (max-width: 1279px) {
    .impact-statistics-overview-table td:not(:first-child),
    .impact-statistics-overview-table th:not(:first-child) {
        // background: red;
        padding-left: 1.125rem;
        padding-right: 1.125rem;
    }
    .impact-statistics-overview-table td:last-child {
        padding-right: 1.5rem;
    }
}

@media screen and (min-width: 1280px) {
    .impact-statistics-overview-table td:last-child {
        padding-right: 2.125rem;
    }
}

.impact-statistics-overview-table td {
    padding: 0.875rem 1.5rem;
}
.impact-statistics-overview-table td:first-child {
    width: 100%;
}
.impact-statistics-overview-table td:first-child .cell-contents {
    height: 2.25rem;
    display: flex;
    align-items: center;
}
</style>
