<template>
    <!-- Account Info -->
    <div class="account-menu-popover-account-info">
        <div class="avatar-s">
            <ProfileImage
                :width="36"
                :image-url="profileImageUrl"
                :avatar-url="currentUserAvatarUrl"
            />
        </div>
        <div>
            <p class="account-menu-popover-name">{{ userInfo?.name }}</p>
            <p class="account-menu-popover-email">{{ userInfo?.username }}</p>
        </div>
    </div>

    <!-- Account Settings -->
    <div tabindex="0" @click="pushRoute(linkToAccountSettings)" class="account-menu-popover-link">
        <span>Account Settings</span>
    </div>

    <!-- Linked Accounts -->
    <div
        v-if="userInfo?.user_id"
        id="linkedAccounts"
        tabindex="0"
        @click="pushRoute(linkToLinkedAccounts)"
        class="account-menu-popover-link"
    >
        <span>Linked Accounts</span>
    </div>

    <!-- Impact Statistics -->
    <div
        v-if="betaFeatures?.impactStatistics"
        tabindex="0"
        @click="pushRoute(linkToImpactStatistics)"
        class="account-menu-popover-link"
    >
        <span>Impact Statistics</span>
    </div>

    <!-- Billing Centre -->
    <div
        v-if="userInfo?.role === 'admin'"
        id="billing"
        tabindex="0"
        @click="pushRoute(linkToBillingCentre)"
        class="account-menu-popover-link"
    >
        <span>Billing Centre</span>
    </div>

    <!-- Logout -->
    <div id="logout" tabindex="0" @click="logout()" class="account-menu-popover-link">
        <span>Logout</span>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, inject } from 'vue'
import { useRouter } from 'vue-router'

import { oButton, ProfileImage } from '@opteo/components-next'
import { useUser } from '@/composition/user/useUser'
import { useLogin } from '@/composition/auth/useLogin'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'

export default defineComponent({
    name: 'AccountSidebar',
    components: { ProfileImage, oButton },
    setup(_, { emit }) {
        const { userInfo, profileImageUrl, betaFeatures } = useUser()
        const { currentUserAvatarUrl } = useBoringAvatar()

        const { logout } = useLogin()

        const linkToAccountSettings = computed(() => {
            return `/user/${userInfo.value?.user_id}/settings`
        })

        const linkToBillingCentre = computed(() => {
            return `/user/${userInfo.value?.user_id}/billing/subscription`
        })

        const linkToFeatureRequests = computed(() => {
            return `/user/${userInfo.value?.user_id}/featurerequests`
        })

        const linkToLinkedAccounts = computed(() => {
            return `/user/${userInfo.value?.user_id}/linkedaccounts`
        })

        const linkToImpactStatistics = computed(() => {
            return `/user/${userInfo.value?.user_id}/impactstatistics`
        })

        const accountPopoverOpen = ref<boolean>(false)
        const target = ref(null)

        const router = useRouter()

        const closeMobileNav = inject<() => void>('closeMobileNav', () => {})

        function pushRoute(route: string) {
            const toRoute = route.split('/').pop()
            const currentRoute = router.currentRoute.value.path.split('/').pop()

            if (toRoute === currentRoute) {
                closeMobileNav()
            }

            router.push(route)
        }

        return {
            userInfo,
            logout,
            profileImageUrl,
            linkToAccountSettings,
            linkToBillingCentre,
            linkToFeatureRequests,
            linkToLinkedAccounts,
            linkToImpactStatistics,
            accountPopoverOpen,
            target,
            pushRoute,
            currentUserAvatarUrl,
            betaFeatures,
        }
    },
})
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.account-menu-popover-account-info {
    @include flex;
    @include items-center;
    @include pa-24;
    @include container;
    @include br-20;
    @include mb-32;
    overflow: hidden;
}

.account-menu-popover-name {
    @include ml-16;
    @include opteo-black;
    @include f-7;
    @include fw-500;
    line-height: 1.25rem;
    margin-bottom: 4px;
    white-space: nowrap;
    max-width: 13.375rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.account-menu-popover-email {
    @include ml-16;
    @include f-9;
    @include fw-400;
    line-height: 1rem;
    @include o-30;
    white-space: nowrap;
    max-width: 13.375rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.account-menu-popover-link {
    @include flex;
    @include items-center;
    gap: 0.625rem;
    height: 2.875rem;
    @include opteo-black;
    @include f-8;
    @include mb-8;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    border-radius: 0.75rem;
    cursor: pointer;
    transition:
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}
.account-menu-popover-link:last-child {
    @include mb-0;
}
.account-menu-popover-link:hover {
    // background: $opteo-light-gray;
    box-shadow: $opteo-shadow;
}
.account-menu-popover-link:focus {
    outline: none;
    box-shadow: $opteo-shadow-focus;
}

.account-menu-popover-link:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
}

@media screen and (max-width: $mq-767-max) {
    .account-menu-popover-account-info {
        @include pa-20;
        @include mb-24;
    }
}
@media screen and (max-width: 374px) {
    .avatar-s {
        display: none;
    }
    .account-menu-popover-name,
    .account-menu-popover-email {
        margin-left: unset;
    }
}
</style>
