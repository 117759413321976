import { computed, inject, provide, ref, Ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useLocalStorage } from '@vueuse/core'
import { Domain, Account, User, Api } from '@opteo/types'

import { LS_AC_SORTBY } from '@/lib/cookies'
import { Endpoint, useAPI } from '@/composition/api/useAPI'
import { Routes } from '@/router/routes'
import { searchForAccountMatcher } from '@/lib/search'
import { sortAccountList, SortBy } from '@/lib/accountList'
import { Platform } from '@opteo/types/platform/platform'

const searchedDomain = ref('')

export function provideAccountList(user: Ref<Api.GetUserInfo.Response | undefined>) {
    const router = useRouter()

    const {
        data: rawAccountList,
        loading,
        mutate,
    } = useAPI<(Account.Info & { budgetBar: Domain.BudgetBar })[]>(Endpoint.GetUserAccounts)

    const accountList = computed(
        () =>
            rawAccountList.value?.filter(a => {
                if (a.platform === Platform.GoogleAds) return true // always show GA accounts
                if (a.platform === Platform.MetaAds)
                    return user.value?.profile.betaFeatures.metaBeta
                if (a.platform === Platform.MicrosoftAds)
                    return user.value?.profile.betaFeatures.microsoftGamma // only show MS accounts if beta is enabled
            })
    )

    const domainListLoading = computed(() => loading.value || !user.value?.profile.betaFeatures)

    const sortedBy = useLocalStorage<SortBy>(LS_AC_SORTBY, SortBy.Name)

    const userHasNoDomains = computed(() => accountList.value?.length === 0)

    const sortedDomainList = computed(() =>
        sortAccountList(accountList.value ?? [], sortedBy.value)
    )

    const filteredDomainList = computed(() => {
        return sortedDomainList.value.filter(account =>
            searchForAccountMatcher(searchedDomain.value, account)
        )
    })

    const setDomainListOrder = (newOrder: SortBy) => (sortedBy.value = newOrder)

    const gotoFirstSearchedDomain = () => {
        const [firstAccount] = filteredDomainList.value
        if (typeof firstAccount === 'undefined') return

        searchedDomain.value = ''
        router.push({
            name: Routes.Account,
            params: {
                accountId: firstAccount.accountId,
            },
        })
    }

    // Mutate a single account by accountId in the account list
    const mutateAccountList = (accountId?: Account.ID, newFields?: Partial<Account.Info>) => {
        if (typeof accountId === 'undefined' || typeof newFields === 'undefined') {
            mutate()
            return
        }

        const matchingAccount = accountList.value?.find(a => a.accountId === accountId)

        if (!accountList.value) {
            // The domainList is not loaded yet, so we can't mutate it.
            // This can happen when the page is refreshed inside an account, so we attempt to
            // update its `last_visited` field before the domain list is loaded.
            return
        }

        if (!matchingAccount) throw new Error('could not find account to mutate')

        const mutatedAccount: Account.Info = {
            ...matchingAccount,
            ...newFields,
        } as Account.Info

        const mutatedAccountList: Account.Info[] = [
            ...(accountList.value?.filter(a => a.accountId !== accountId) as Account.Info[]),
            mutatedAccount,
        ]

        mutate(() => mutatedAccountList)
    }

    const resetSearch = () => {
        if (searchedDomain.value) {
            searchedDomain.value = ''
        }
    }

    function getAccountInfo(accountId: Account.ID) {
        return accountList.value?.find(a => a.accountId === accountId)
    }

    const toProvide = {
        accountList,
        filteredDomainList,
        gotoFirstSearchedDomain,
        searchedDomain,
        userHasNoDomains,
        sortedDomainList,
        setDomainListOrder,
        mutateDomainList: mutateAccountList,
        domainListLoading,
        sortedBy,
        resetSearch,
        getAccountInfo,
    }

    provide('domainList', toProvide)

    return toProvide
}

export function useAccountList() {
    const injected = inject<ReturnType<typeof provideAccountList>>('domainList')

    if (!injected) {
        throw new Error(
            `domainList not yet injected, something is wrong. useAccountList must be called from a child of /user/.`
        )
    }

    return injected
}
