<template>
    <div class="account-centre-row-container">
        <router-link
            :to="accountClickedLink"
            class="account-centre-row"
            ref="accountCentreRow"
            tabindex="0"
        >
            <!-- Client Name + Avatar -->
            <div class="account-container">
                <Avatar
                    v-if="account?.name"
                    :color="account.color"
                    :platform="account.platform"
                    show-account-type
                >
                    {{ accountInitials }}
                </Avatar>
                <Skeleton v-else :width="36" :height="36" />

                <!-- Account Name -->
                <div :style="{ color: account.color }" class="account-name-container">
                    <div v-if="account.name">
                        <h5 class="account-name">{{ account.name }}</h5>
                        <span class="account-last-active">
                            <span v-if="account.lastVisitedAt"
                                >active {{ accountLastActive }} ago</span
                            >
                            <span v-else>Never opened</span>
                        </span>
                    </div>
                    <div v-else>
                        <Skeleton :width="Math.random() * 120 + 100" />
                        <Spacer height="0.625rem" />
                        <Skeleton :width="112 - Math.random() * 25" :height="10" />
                    </div>
                </div>
            </div>

            <div class="account-centre-row-right" v-if="!isError">
                <!-- Spend + Sparkline -->
                <div class="sparkline-container spend" v-if="!loadingMetrics && spendChart">
                    <Label class="sparkline-title">Spend (30d)</Label>
                    <Sparkline class="sparkline-graph" :items="spendChart.points" />
                    <Money
                        class="sparkline-content"
                        :value="spendChart.thirty_day_total"
                        :currency="account.currencyCode"
                    />
                    <Delta
                        v-if="spendChart.delta < 999"
                        :delta="spendChart.delta / 100"
                        :decimal-places="spendChart.delta > 100 ? 0 : 1"
                        class="sparkline-delta"
                        :smallText="true"
                    />
                    <Delta
                        v-else
                        :delta="9.99"
                        :decimal-places="0"
                        class="sparkline-delta"
                        :smallText="true"
                    />
                </div>
                <div v-else class="sparkline-container spend">
                    <Skeleton :width="65" :height="12" />
                    <SparklineSkeleton class="sparkline-graph" />
                    <Skeleton :width="65" :height="16" class="sparkline-content" />
                    <Skeleton
                        :width="22"
                        :height="22"
                        class="sparkline-delta"
                        style="opacity: 0.67"
                    />
                    <Skeleton
                        :width="30"
                        :height="12"
                        class="sparkline-delta"
                        style="opacity: 0.67"
                    />
                </div>

                <!-- Conversions + Sparkline -->
                <div class="sparkline-container conversions" v-if="!loadingMetrics && convChart">
                    <Label class="sparkline-title">Conv. (30d)</Label>
                    <Sparkline class="sparkline-graph" :items="convChart.points" />
                    <Number
                        class="sparkline-content"
                        :value="Math.round(convChart.thirty_day_total)"
                    />
                    <Delta
                        v-if="convChart.delta < 999"
                        :delta="convChart.delta / 100"
                        :decimal-places="convChart.delta > 100 ? 0 : 1"
                        class="sparkline-delta"
                        :smallText="true"
                    />
                    <Delta
                        v-else
                        :delta="9.99"
                        :decimal-places="0"
                        class="sparkline-delta"
                        :smallText="true"
                    />
                </div>
                <div v-else class="sparkline-container conversions">
                    <Skeleton :width="62" :height="12" />
                    <SparklineSkeleton class="sparkline-graph" />
                    <Skeleton :width="28" :height="16" class="sparkline-content" />
                    <Skeleton
                        :width="22"
                        :height="22"
                        class="sparkline-delta"
                        style="opacity: 0.67"
                    />
                    <Skeleton
                        :width="30"
                        :height="12"
                        class="sparkline-delta"
                        style="opacity: 0.67"
                    />
                </div>

                <!-- Budget Status -->
                <div class="account-centre-row-budget">
                    <div v-if="!budgetBar || loadingMetrics">
                        <Skeleton :width="62" :height="36" style="opacity: 0.67" />
                    </div>
                    <DomainListBudgetBar
                        v-if="!loadingMetrics && budgetBar"
                        :account="account"
                        :account-initials="accountInitials"
                        :budget-bar="budgetBar"
                    />
                </div>

                <Spacer width="1rem" />

                <!-- Available Improvements -->
                <div class="account-centre-row-available-improvements">
                    <Tooltip
                        :content="`${account.tasksAvailable} Available Improvements`"
                        v-if="!loadingMetrics"
                    >
                        <span :style="{ color: account.color }" class="improvement-count">
                            {{ account.tasksAvailable }}
                        </span>
                    </Tooltip>
                    <div v-else>
                        <Skeleton :width="32" :height="32" style="opacity: 0.67" />
                    </div>
                </div>

                <Spacer width="3.25rem" />
            </div>
        </router-link>

        <!-- Settings + Modal -->
        <div
            v-if="account.name && budgetBar && !loadingMetrics && !isError"
            class="account-centre-row-settings"
        >
            <DomainListRowSettings
                :account="account"
                :account-initials="accountInitials"
                :budget-bar="budgetBar"
                @client-updated="updateClient"
            />
        </div>
        <div v-else-if="loadingMetrics && !isError" class="account-centre-row-settings">
            <oButton color="white" :circle="true" size="small">
                <template v-slot:icon>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M15.135 6.784a2 2 0 0 1-1.23-2.969c.322-.536.225-.998-.094-1.316l-.31-.31c-.318-.318-.78-.415-1.316-.094A2 2 0 0 1 9.216.865C9.065.258 8.669 0 8.219 0h-.438c-.45 0-.845.258-.997.865a2 2 0 0 1-2.969 1.23c-.536-.322-.999-.225-1.317.093l-.31.31c-.318.318-.415.781-.093 1.317a2 2 0 0 1-1.23 2.969C.26 6.935 0 7.33 0 7.781v.438c0 .45.258.845.865.997a2 2 0 0 1 1.23 2.969c-.322.536-.225.998.094 1.316l.31.31c.319.319.782.415 1.316.094a2 2 0 0 1 2.969 1.23c.151.607.547.865.997.865h.438c.45 0 .845-.258.997-.865a2 2 0 0 1 2.969-1.23c.535.321.997.225 1.316-.094l.31-.31c.318-.318.415-.78.094-1.316a2 2 0 0 1 1.23-2.969c.607-.151.865-.547.865-.997v-.438c0-.451-.26-.846-.865-.997ZM8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
                            fill="#E0E0E5"
                        />
                    </svg>
                </template>
            </oButton>
        </div>
        <div v-else-if="isError" class="account-centre-row-settings">
            <div class="desktop">
                <Text color="gray" size="f-8"> Unable to fetch data for this account </Text>
            </div>
            <div class="mobile">
                <Tooltip content="Unable to fetch data for this account">
                    <WarnIcon style="display: block" />
                </Tooltip>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { ALWAYS_SHOW_ONBOARDING } from '@/lib/env'

import { computed, defineComponent, PropType, ref } from 'vue'
import {
    Number,
    Money,
    Percent,
    Sparkline,
    Label,
    Delta,
    Spacer,
    Text,
    oButton,
    Tooltip,
    Avatar,
    WarnIcon,
} from '@opteo/components-next'
import { Account, Platform } from '@opteo/types'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import parseISO from 'date-fns/parseISO'

import { getPlatformFromId } from '@/lib/globalUtils'
import { useAccountList } from '@/composition/user/useAccountList'
import { useDomainListRow } from '@/composition/user/useDomainListRow'
import { getDomainInitials } from '@/composition/domain/useDomain'
import { Routes } from '@/router/routes'
import DomainListBudgetBar from '@/components/AccountCenter/DomainListBudgetBar.vue'
import DomainListRowSettings from '@/components/AccountCenter/DomainListRowSettings.vue'

import SparklineSkeleton from '@/components/AccountCenter/SparklineSkeleton.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import { useUser } from '@/composition/user/useUser'

export default defineComponent({
    props: {
        account: {
            type: Object as PropType<Account.Info>,
            required: true,
        },
    },
    components: {
        Avatar,
        Skeleton,
        Money,
        Percent,
        Number,
        Sparkline,
        Label,
        Delta,
        Tooltip,
        Spacer,
        Text,
        oButton,
        SparklineSkeleton,
        DomainListBudgetBar,
        DomainListRowSettings,
        WarnIcon,
    },
    setup(props: { account: Account.Info }) {
        const { groupId } = useUser()
        const { searchedDomain, domainListLoading: loadingDomain } = useAccountList()
        const {
            loadingMetrics,
            spendChart,
            convChart,
            budgetBar,
            rowIsVisible,
            accountCentreRow,
            getDeltaColour,
            updateClient,
            isError,
        } = useDomainListRow(ref(props.account.accountId))

        const platform = computed(() => {
            return getPlatformFromId(props?.account?.accountId)
        })

        const disconnecting = ref(false)

        const accountClickedLink = computed(() => {
            if (!props.account?.accountId || platform.value === Platform.Platform.MetaAds) return {}

            // Go to setup if the account setup has never been visited
            if (ALWAYS_SHOW_ONBOARDING || !props.account.accountSetupSeen) {
                return {
                    name: Routes.Setup,
                    params: {
                        accountId: props.account?.accountId,
                    },
                }
            }

            // Otherwise go to the account
            return {
                name: Routes.Account,
                params: {
                    accountId: props.account.accountId,
                },
            }
        })

        const accountInitials = computed(() => getDomainInitials(props.account.name))
        const accountLastActive = computed(() =>
            formatDistanceToNowStrict(parseISO(props.account.lastVisitedAt as unknown as string))
        )

        const resetSearch = () => (searchedDomain.value = '')

        return {
            accountInitials,
            accountLastActive,
            accountClickedLink,
            resetSearch,
            disconnecting,
            loadingMetrics,
            loadingDomain,
            rowIsVisible,
            accountCentreRow,
            spendChart,
            convChart,
            budgetBar,
            getDeltaColour,
            updateClient,
            isError,
            Platform,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

/* Domain List Item Container */
.account-centre-row-container {
    @include relative;
}
.account-centre-row {
    @include flex;
    @include items-center;
    @include opteo-background;
    @include br-24;
    @include mb-16;
    height: 5rem;
    overflow: hidden;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    outline: none;
}

.account-centre-row:focus {
    box-shadow: $opteo-shadow-focus;
    outline: none;
}
.account-centre-row:active {
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
    -webkit-transform: translate3d(0, 1px, 0);
    -moz-transform: translate3d(0, 1px, 0);
    outline: none;
}

.account-centre-row-right {
    @include absolute;
    @include flex;
    @include bg-opteo-white;
    @include pr-24;
    right: 0;
    height: 100%;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}

.account-centre-row .account-container {
    @include flex;
    @include items-center;
    @include pl-24;
    flex-grow: 1;
    height: 100%;
}
.account-centre-row .account-name-container {
    @include flex;
    @include items-center;
    @include ml-20;
    flex-grow: 1;
    height: 100%;
}
.account-centre-row .account-name-container .account-name {
    white-space: nowrap;
    @include f-7;
    @include fw-500;
    line-height: 1.125rem;
    letter-spacing: -0.0075rem;
}
.account-centre-row .account-name-container .account-last-active {
    white-space: nowrap;
    @include f-10;
    @include opteo-black;
    opacity: 0.32;
    line-height: 0.875rem;
}

.account-centre-row-available-improvements {
    @include flex;
    @include items-center;
    height: 100%;
}
.account-centre-row .improvement-count {
    @include br-999;
    @include flex;
    @include items-center;
    @include justify-center;
    @include f-10;
    @include fw-500;
    width: 2rem;
    height: 2rem;
    letter-spacing: -0.01rem;
    border: 1px solid #f2f2f5;
}
.account-centre-row .improvement-count span {
    transform: translateY(-1px);
}

.sparkline-container {
    @include flex;
    @include items-center;
    @include relative;
    @include no-select;
    @include opteo-foreground;
    flex-grow: 0;
}
.sparkline-container.spend {
    width: 342px;
    flex: 0 0 342px;
    height: 100%;
}
.sparkline-container.spend::after {
    content: '';
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 62.5%);
    position: absolute;
    top: 0;
    left: -6rem;
    height: 100%;
    width: 6rem;
    z-index: 2;
}
.sparkline-container.conversions {
    width: 312px;
    flex: 0 0 312px;
    // @include ph-16;
}
.sparkline-title {
    margin-bottom: 0;
    white-space: nowrap;
}
.sparkline-graph {
    width: 4rem;
    flex: 0 0 4rem;
    @include mh-16;
}
.sparkline-content {
    @include f-7;
    @include fw-600;
    letter-spacing: -0.0375rem;
}
.sparkline-delta {
    @include ml-12;
}
.sparkline-delta svg {
    @include mr-8;
}

.account-centre-row-budget {
    height: 100%;
    @include flex;
    @include items-center;
    @include relative;
    @include no-select;
}
.account-centre-row-settings {
    @include absolute;
    top: 0;
    right: 0;
    height: 100%;
    @include flex;
    @include items-center;
    @include pr-24;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}

.account-centre-row-settings .desktop {
    @include flex;
    @include items-center;
}
.account-centre-row-settings .mobile {
    display: none;
    width: 2.25rem;
    height: 2.25rem;
}

@media screen and (min-width: 0) and (max-width: 413px) {
    .account-centre-row-right,
    .account-centre-row-settings {
        display: none;
    }
}
@media screen and (min-width: 414px) and (max-width: 599px) {
    .account-centre-row-right {
        display: none;
    }
    .account-centre-row-settings {
        background: rgb(255, 255, 255);
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 1.5rem
        );
    }
    .account-centre-row-settings .desktop {
        display: none;
    }
    .account-centre-row-settings .mobile {
        @include flex-center;
    }
}
@media screen and (min-width: 600px) and (max-width: 1023px) {
    .sparkline-container.conversions,
    .sparkline-container.spend {
        display: none;
    }
    .account-centre-row-settings .desktop {
        display: none;
    }
    .account-centre-row-settings .mobile {
        @include flex-center;
    }
}
@media screen and (min-width: $mq-1024-max) and (max-width: $mq-1279-max) {
    .sparkline-container.conversions {
        display: none;
    }
}
</style>
