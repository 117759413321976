<template>
    <div :key="platform">
        <router-view />
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import { Platform } from '@opteo/types'
const { push, currentRoute } = useRouter()

// PlatformSwitcher
const platform = computed(() => {
    if (currentRoute.value.name === Routes.LinkedAccountsGoogle) {
        return Platform.Platform.GoogleAds
    }
    return Platform.Platform.MicrosoftAds
})
</script>
