<template>
    <div>
        <oTable
            class="impact-statistics-alerts-section-table"
            :headers="alertsTableHeaders"
            :items="alertsTableRows"
            :per-page="6"
            :borderRadius="24"
            order-by="budget"
            order="DESC"
        >
            <!-- Account Name -->
            <template v-slot:header.accountName>
                <div class="table-search-container">
                    <Label class="table-search-label">Account Name</Label>
                    <Spacer width="1rem" />
                    <oInput
                        ref="searchInput"
                        type="text"
                        class="account-search-input"
                        name="accountSearchInputCost"
                        autocomplete="off"
                        v-model="searchedAccount"
                    />
                </div>
            </template>
            <template v-slot:column.accountName="cell">
                <ImpactStatsAccountNameCell
                    :domainName="cell.value.name"
                    :domainColor="cell.value.color"
                    :platform="cell.value.platform"
                    :initials="cell.value.initials"
                    :validating="validating"
                />
            </template>
            <template v-slot:column.accountBreakdown="cell">
                <oButton color="white" size="small" @clicked="openModal(cell.value)">
                    Detailed View
                </oButton>
            </template>
            <!-- Skeletons -->
            <template v-if="validating" v-slot:column.budget>
                <Skeleton width="2.5rem" />
            </template>
            <template v-if="validating" v-slot:column.kpiAnom>
                <Skeleton width="2.5rem" />
            </template>
            <template v-if="validating" v-slot:column.zeroImpr>
                <Skeleton width="2.5rem" />
            </template>
            <template v-if="validating" v-slot:column.brokenLink>
                <Skeleton width="2.5rem" />
            </template>
        </oTable>
        <!-- Account Breakdown Modal -->
        <Modal
            v-model="alertBreakdownModalOpen"
            title="Critical Alerts Breakdown"
            width="78.5rem"
            @closed="resetNumberOfVisibleRows"
        >
            <template #content>
                <ImpactStatisticsAlertRow
                    v-for="(row, index) in slicedRows"
                    :alert="row"
                    :class="index === slicedRows.length - 1 ? 'last' : ''"
                />
                <div v-if="modalRows.length > slicedRows.length" class="show-more-button-wrapper">
                    <oButton color="white" @clicked="showMoreRows">Show More</oButton>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script setup lang="ts">
// vue
import { computed, ref, PropType } from 'vue'
// types
import { AccountData } from '@/composition/impactStatistics/useImpactStatistics'
import { IS } from '@opteo/types'
// local components
// local components
import ImpactStatsAccountNameCell from '@/components/impactStatistics/ImpactStatsAccountNameCell.vue'
import ImpactStatisticsAlertRow from '@/components/impactStatistics/ImpactStatisticsAlertRow.vue'
import Skeleton from '@/components/util/Skeleton.vue'
// composition
import useImpactStatistics from '@/composition/impactStatistics/useImpactStatistics'
// components-next
import { oTable, Label, Spacer, oInput, oButton, Modal } from '@opteo/components-next'
// lodash
import includes from 'lodash-es/includes'
import startCase from 'lodash-es/startCase'
import sortBy from 'lodash-es/sortBy'
// date-fns
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

type AlertTableRowData = {
    id: string
    accountName: AccountData
    accountBreakdown: AlertAccountBreakdown
    budget: number
    brokenLink: number
    kpiAnom: number
    zeroImpr: number
}

type AlertModalRow = {
    account: AccountData
    title: string
    ts: string | Date
    date: string
}

export type AlertAccountBreakdown = {
    domainInfo: AccountData
    instances: { alerts: IS.CriticalAlert[]; improvements: IS.CriticalImprovementAlert[] }
}

const props = defineProps({
    data: {
        type: Array as PropType<AlertTableRowData[]>, // Could add custom type later, for now it may  still change
        required: true,
    },
    validating: {
        type: Boolean,
        default: false,
    },
})

const {
    formatDateFromTimestamp,
    generateAlertRouterLink,
    generateImprovementRouterLink,
    openUrlInNewTab,
    viewportHeight,
} = useImpactStatistics()

const maxModalRows = computed(() => Math.floor((0.45 * viewportHeight.value) / 74))

const alertBreakdownModalOpen = ref(false)

const searchedAccount = ref('')

// Alerts section
const alertsTableHeaders = [
    { key: 'accountName', text: 'Account Name', noPadding: true },
    { key: 'budget', text: 'Budget', sortable: true },
    { key: 'kpiAnom', text: 'Metric', sortable: true },
    { key: 'zeroImpr', text: 'Zero Impr.', sortable: true },
    { key: 'brokenLink', text: 'Broken Link', sortable: true },
    { key: 'accountBreakdown', text: 'Actions' },
]

const alertsTableRows = computed(() => {
    if (!searchedAccount.value) return props.data
    else
        return props.data.filter(item =>
            includes(item.accountName.name.toLowerCase(), searchedAccount.value.toLowerCase())
        )
})

const modalRows = ref<AlertModalRow[]>([])

const generateAlertModalRows = (data: AlertAccountBreakdown): AlertModalRow[] => {
    // Format improvements to match structure of alerts
    const fixBrokenLinks = data.instances.improvements.map(imp => {
        return {
            title: startCase(imp.recAction.replaceAll('_', ' ')),
            ts: imp.ts,
            improvementId: imp.improvementId,
            domainId: imp.domainId,
        }
    })
    // Merge arrays for alerts & imps
    const alertsAndImps = [...data.instances.alerts, ...fixBrokenLinks]
    // Turn into rows
    const rows = alertsAndImps.map(instance => {
        return {
            account: data.domainInfo, // Account info always the same in account breakdown
            title: instance.title,
            ts: instance.ts,
            date: formatDateFromTimestamp(instance.ts), // used to be formatted as 'MMMM do yyyy' but now `MMMM do y` using the function.
        }
    })
    // Sort & return
    return sortBy(rows, ['ts']).reverse()
}

const openModal = (accountData: AlertAccountBreakdown) => {
    // Generate table cells from data
    modalRows.value = generateAlertModalRows(accountData)
    // Open the modal
    alertBreakdownModalOpen.value = true
}

const modalTableHeaders = [
    { key: 'account', text: 'Account', noPadding: true },
    { key: 'title', text: 'Title' },
    { key: 'date', text: 'Date', sortable: true },
]

const numberOfVisibleRows = ref(50)
const slicedRows = computed(() => {
    return modalRows.value.slice(0, numberOfVisibleRows.value)
})
const showMoreRows = () => {
    numberOfVisibleRows.value = numberOfVisibleRows.value + 50
}
const resetNumberOfVisibleRows = () => {
    numberOfVisibleRows.value = 50
}
</script>

<!-- Scoped -->
<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

@media screen and (max-width: 1279px) {
    .account-search-input {
        display: none;
    }
}

@media screen and (min-width: 1280px) {
    .account-search-input {
        max-width: 12rem;
        @include relative;
    }
    .account-search-input :deep(input) {
        padding: 10px 14px 10px 38px;
    }
    .account-search-input::before {
        content: '';
        position: absolute;
        left: 14px;
        top: 0;
        bottom: 0;
        width: 12px;
        background: url('@/assets/img/searchIcon.svg') center / contain no-repeat;
    }
}

// Modal
.show-more-button-wrapper {
    width: 100%;
    @include flex-center;
    @include mt-24;
}
</style>

<!-- Unscoped -->
<style lang="scss">
@media screen and (max-width: 1279px) {
    .impact-statistics-alerts-section-table td:not(:first-child),
    .impact-statistics-alerts-section-table th:not(:first-child) {
        padding-left: 1.125rem;
        padding-right: 1.125rem;
    }
    .impact-statistics-alerts-section-table td:last-child {
        padding-right: 1.5rem;
    }
    .impact-statistics-alerts-section-table td:nth-child(5),
    .impact-statistics-alerts-section-table th:nth-child(5) {
        padding-right: 0.5rem;
    }
    .impact-statistics-alerts-section-table .impact-stats-table-domain-name {
        max-width: 11rem;
    }
}

@media screen and (min-width: 1280px) {
    .impact-statistics-alerts-section-table td:last-child {
        padding-right: 2.125rem;
    }
}
</style>
