<template>
    <Text as="h5" size="f-5" weight="600" style="line-height: 1.75rem">
        {{ alert.title }}
    </Text>
    <Spacer height="1.25rem" />
    <div v-if="!isDailyAlert">
        <Text as="p" size="f-8" v-if="farOver">
            So far this month, your account is overspending significantly compared to your monthly
            budget. We are currently
            <b><Percent :value="percentMonth" /></b> through the month and you have spent
            <b><Percent :value="percentSpend" /></b> of your budget.
            <Spacer height="1rem" />
            Your projected monthly spend is
            <b><Money :value="projectedSpend" :currency="currencyCode" /></b>, compared with a
            budget of <b><Money :value="budget" :currency="currencyCode" /></b>. If you continue
            spending at the current rate, you will overspend by
            <b><Percent :value="overspendPercent" /></b>.
        </Text>
        <Text as="p" size="f-8" v-else>
            Your account is underspending significantly compared to your monthly budget. At the time
            of this alert, we are
            <b><Percent :value="percentMonth" /></b> through the month and only
            <b><Percent :value="percentSpend" /></b> of your budget has been spent.
            <Spacer height="1rem" />
            Your projected monthly spend is
            <b><Money :value="projectedSpend" :currency="currencyCode" /></b>, compared with a
            budget of <b><Money :value="budget" :currency="currencyCode" /></b>. If you continue
            spending at the current rate, you will underspend by
            <b><Percent :value="underspendPercent" /></b>.
        </Text>
    </div>
    <div v-else>
        <Text as="p" size="f-8">
            On <b>{{ date }}</b> your account exceeded its monthly budget. You have spent
            <b><Money :value="spend" :currency="currencyCode" /></b> so far this month. This is
            <b><Percent :value="currentOverspendPercent" /></b> more than your monthly budget of
            <b><Money :value="budget" :currency="currencyCode" /></b>.
            <span v-if="daysRemaining === 1">
                There is <b><Number :value="daysRemaining" /></b> day left in the month.
            </span>
            <span v-else>
                There are
                <b><Number :value="daysRemaining" /></b> days left in the month.
            </span>
            At the end of this month, your total spend will be
            <b><Money :value="projectedSpend" :currency="currencyCode" /></b> if you continue
            spending at the current rate.
            <Spacer height="1rem" />
            If possible, temporarily pause campaigns to limit overspending, otherwise consider
            adjusting your budget in the settings tab.
        </Text>
    </div>
    <Spacer height="2rem" />
    <div class="chart-container">
        <LineChart
            :series="chartData"
            :metric="{ dataType: 'money', currency: currencyCode }"
            :horizontal-markers="budgetMarker"
            :show-zero="true"
            :chart-height="180"
            disable-fallback
        >
            <template #tooltip="{ hoveredItem }">
                <ChartTooltip
                    v-if="hoveredItem"
                    :plot-lines="generateTooltipPlotlines(hoveredItem)"
                />
            </template>
            <template #markerText="{ markerInfo }">
                <ColorTag
                    color="red"
                    :title="markerInfo?.label"
                    :content="formattedBudget.displayValue.value"
                />
            </template>
        </LineChart>
    </div>

    <Spacer height="2rem" />

    <AlertNotes :alert-date="isoDate" :account-id="alert?.accountId ?? ('' as Account.ID)" />
</template>

<script lang="ts">
import { computed, defineComponent, Ref } from 'vue'
import {
    Text,
    LineChart,
    Spacer,
    Number,
    Percent,
    LineChartTypes,
    ChartTooltip,
    fillMissingDates,
    ColorTag,
} from '@opteo/components-next'
import { useMoney } from '@opteo/components-next'
import parseISO from 'date-fns/parseISO'
import formatDate from 'date-fns/format'
import endOfMonth from 'date-fns/endOfMonth'
import differenceInDays from 'date-fns/differenceInDays'
import { Account, BudgetAlert } from '@opteo/types'
import { adaptDateToTimezone } from '@/composition/misc/useTimezone'

import AlertNotes from '@/components/notes/AlertNotes.vue'
import Money from '@/components/global/oMoney.vue'
import { useAlert } from '@/composition/alerts/useAlert'
import subMonths from 'date-fns/subMonths'
import addMonths from 'date-fns/addMonths'
import groupBy from 'lodash-es/groupBy'
import startOfMonth from 'date-fns/startOfMonth'

export default defineComponent({
    name: 'Flatline',
    components: {
        Text,
        LineChart,
        Spacer,
        Number,
        Money,
        Percent,
        AlertNotes,
        ChartTooltip,
        ColorTag,
    },
    setup() {
        const { alert, currencyCode } = useAlert<BudgetAlert.Body>()
        if (!alert.value) {
            throw new Error('An alert ID must be set before initializing this alert.')
        }

        /*
            body.categories: current month points (not used)
            body.data1: this month spend so far + projection
            body.points: previous month spend
        */
        const isoDate = parseISO(alert.value?.ts)
        const month = formatDate(isoDate, 'MMMM')
        const percentMonth = alert.value?.body.month_percent / 100
        const date = formatDate(isoDate, 'MMMM do')
        const daysRemaining = differenceInDays(endOfMonth(isoDate), isoDate)

        const spend = alert.value?.body.spend
        const budget = alert.value?.body.budget
        const overspendPercent =
            (alert.value?.body.projected_spend - alert.value?.body.budget) /
            alert.value?.body.budget
        const underspendPercent =
            ((alert.value?.body.projected_spend - alert.value?.body.budget) /
                alert.value?.body.budget) *
            -1
        const currentOverspendPercent =
            (alert.value?.body.spend - alert.value?.body.budget) / alert.value?.body.budget
        const percentSpend = alert.value?.body.percent_spend / 100
        const projectedSpend = alert.value?.body.projected_spend

        const isDailyAlert = alert.value?.body.status === 'daily_over'
        const farOver = alert.value?.body.status === 'far_over'

        const chartData = computed<LineChartTypes.LineChartSeries[]>(() => {
            // Due to a bug in the backend, the last few points of cost can be on the same date.
            // This groupBy throws away any points with duplicate dates.
            const previousMonthPoints = Object.values(groupBy(alert.value?.body.points, 'x')).map(
                rows => {
                    const { x, y } = rows[0]
                    return { x, y }
                }
            )

            /** Hacking previous month data with current month's days so we get overlapping lines on chart */
            const previousMonthPointsFilled = fillMissingDates(previousMonthPoints, {
                defaultPointValue: 'previous_point',
                minDate: startOfMonth(adaptDateToTimezone(isoDate)),
                maxDate: addMonths(endOfMonth(subMonths(adaptDateToTimezone(isoDate), 1)), 1), // end of "last" month
            })

            previousMonthPointsFilled.forEach(date => {
                const day = new Date(date.x!)
                date.x = day
            })

            const currentMonthPointsFilled = alert.value?.body.data1.map(
                ({ x, y, is_projection }) => {
                    const date = new Date(x)
                    const day = adaptDateToTimezone(date)
                    return { x: day, y, dotted: is_projection }
                }
            )

            const previousMonth: LineChartTypes.LineChartSeries = {
                name: 'Previous Month',
                items: previousMonthPointsFilled,
            }

            const currentMonth: LineChartTypes.LineChartSeries = {
                name: 'Current Month',
                items: currentMonthPointsFilled ?? [],
            }

            return [previousMonth, currentMonth]
        })

        const budgetMarker = computed(() => {
            return [{ label: 'Budget', value: budget, color: 'red' }]
        })

        function generateTooltipPlotlines(
            hoveredItems: { displayYValue: Ref<string>; x: Date; dotted: boolean; color: string }[]
        ) {
            if (!hoveredItems) return

            const tooltips: { label: string; value: string; dotted: boolean; color: string }[] = []
            hoveredItems.forEach((item, index) => {
                if (!item) return

                const label = formatDate(subMonths(new Date(item.x), index === 1 ? 0 : 1), 'do MMM')
                const value = item.displayYValue.value
                const dotted = item.dotted
                const color = item.color

                tooltips.push({ label, value, dotted, color })
            })

            return tooltips
        }

        const formattedBudget = useMoney({ value: budget, currency: currencyCode.value })

        return {
            alert,
            month,
            percentMonth,
            date,
            daysRemaining,
            spend,
            budget,
            overspendPercent,
            currentOverspendPercent,
            underspendPercent,
            percentSpend,
            projectedSpend,
            isDailyAlert,
            farOver,
            chartData,
            isoDate,
            budgetMarker,
            generateTooltipPlotlines,
            currencyCode,
            formattedBudget,
        }
    },
})
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.chart-container {
    @include container;
    @include pa-24;
}
</style>
