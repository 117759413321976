<template>
    <Text as="h5" size="f-5" weight="600" style="line-height: 1.75rem">
        <div>{{ alert.title }}</div>
    </Text>
    <Spacer height="1.25rem" />

    <Text as="p" size="f-8"> To check the results of your experiments, click below. </Text>
    <Spacer height="1.5rem" />
    <router-link :to="domainSettingsRoute" @click="alertModalOpen = false">
        <oButton>Open Bidding Experiments</oButton>
    </router-link>
    <Spacer height="1.5rem" />

    <Text as="p" size="f-8"> The following campaigns were included in the experiment: </Text>

    <Spacer height="1.25rem" />

    <oTable
        :headers="[
            {
                key: 'campaign_label',
                text: 'Campaign',
                width: 270,
            },
        ]"
        :items="tableItems"
        :fixed-layout="true"
        order-by="campaign_label"
        order="ASC"
    >
        <template #column.campaign_label="campaign">
            <Tooltip
                placement="top-start"
                :offset="[-10, 2]"
                :content="campaign.value.length > 24 ? campaign.value : ''"
                :max-width="600"
            >
                <div class="campaign-name table-cell overflow">
                    <CircleCampaignIcon />
                    <Text as="p" size="f-8">{{ campaign.value }}</Text>
                </div>
            </Tooltip>
        </template>
        <template #column.status="status">
            <Text as="p" size="f-8" :color="status.value === 'Paused' ? 'red' : 'black'">
                {{ status.value }}
            </Text>
        </template>
    </oTable>

    <Spacer height="2rem" />

    <AlertNotes :alert-date="isoDate" :account-id="alert?.accountId ?? ('' as Account.ID)" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import {
    Text,
    Spacer,
    Number,
    Percent,
    oButton,
    oTable,
    Tooltip,
    OpteoLogo,
    CircleCampaignIcon,
} from '@opteo/components-next'
import parseISO from 'date-fns/parseISO'
import { SmartBiddingExperimentAlert, Account } from '@opteo/types'

import { Routes } from '@/router/routes'
import AlertNotes from '@/components/notes/AlertNotes.vue'
import Money from '@/components/global/oMoney.vue'
import { useAlert } from '@/composition/alerts/useAlert'

export default defineComponent({
    name: 'SmartBiddingExperiment',
    components: {
        Text,
        Spacer,
        Number,
        Money,
        Percent,
        AlertNotes,
        oButton,
        oTable,
        Tooltip,
        OpteoLogo,
        CircleCampaignIcon,
    },
    setup() {
        const { alert, currencyCode, alertModalOpen, accountId } =
            useAlert<SmartBiddingExperimentAlert.Body>()

        if (!alert.value) {
            throw new Error('An alert ID must be set before initializing this alert.')
        }

        const { body } = alert?.value
        const { baseCampaigns, optExperimentId } = body

        const tableItems = baseCampaigns?.map(campaign => {
            return {
                campaign_label: campaign.name,
            }
        })

        const isoDate = parseISO(alert.value?.ts)

        const domainSettingsRoute = computed(() => {
            return {
                name: Routes.ToolkitSmartBiddingExperimentsActive,
                params: {
                    accountId: accountId.value,
                },
            }
        })

        return {
            alert,
            isoDate,
            currencyCode,
            tableItems,
            status,
            domainSettingsRoute,
            alertModalOpen,
        }
    },
})
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.table-label-column {
    @include relative;
    overflow: hidden;
    @include flex;
    @include items-center;
    gap: 0.75rem;
    @include relative;
    height: 3.75rem;
}
.table-label-column .table-label::after {
    content: '';
    background: linear-gradient(270deg, #fff 0.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: -10px;
    width: 3rem;
    bottom: 0;
}
.campaign-name {
    @include flex;
    @include items-center;
    gap: 0.75rem;
    @include relative;
}
</style>
