<template>
    <SettingsSection
        id="subscription-overview"
        title="Subscription"
        buttonLabel="Change Plan"
        :buttonAction="openPlansModal"
        :maxWidth="594"
    >
        <div v-if="currentPlan" class="subscription-overview-container">
            <div class="subscription-overview-text">
                <Text size="f-8">
                    You are subscribed to the <b>{{ currentPlan.name }}</b> plan, which costs
                    <b><Money :value="currentPlan.price" currency="USD" /></b> per
                    {{ currentPlan.period_interval }}.
                    <span v-if="currentPlan.spend_limit && currentPlan.account_limit">
                        Your plan includes <b>{{ currentPlan.account_limit }}</b> linked accounts
                        and
                        <b><Money :value="currentPlan.spend_limit" currency="USD" /></b>
                        spend/month. You currently have <b>{{ totalActiveAccounts }}</b> account(s)
                        linked to Opteo with a spend of
                        <b><Money :value="last30DaySpend" currency="USD" /></b> per month.
                    </span>
                    <span v-else-if="currentPlan.spend_limit">
                        Your plan includes <b>Unlimited</b> linked accounts and
                        <b><Money :value="currentPlan.spend_limit" currency="USD" /></b>
                        spend/month. You currently have
                        <b>{{ totalActiveAccounts }}</b> account(s) linked to Opteo with a spend of
                        <b><Money :value="last30DaySpend" currency="USD" /></b> per month.
                    </span>
                    <span v-else>
                        Your plan includes <b>Unlimited</b> linked accounts and
                        <b>Unlimited</b> spend/month. You currently have
                        <b>{{ totalActiveAccounts }}</b> account(s) linked to Opteo with a spend of
                        <b><Money :value="last30DaySpend" currency="USD" /></b> per month.
                    </span>
                    <Spacer height="0.75rem" />
                    Your next invoice will be for
                    <b><Money :value="nextInvoice" currency="USD" /></b>, you will be billed on
                    <b>{{ nextChargeDate }}</b
                    >.
                </Text>
            </div>
            <Spacer height="1.875rem" />
            <div class="subscription-overview-bars">
                <!-- Linked Accounts -->
                <div class="linked-accounts-bar">
                    <Text as="h5" style="font-size: 1.125rem" weight="600">
                        {{ totalActiveAccounts }} Linked Accounts
                    </Text>
                    <Spacer height="0.875rem" />
                    <ProgressBar
                        id="progressBar"
                        :progress="accountRatio"
                        :barColor="spendPBColor"
                    />
                    <Spacer height="0.75rem" />
                    <Text v-if="currentPlan.account_limit" size="f-9" color="gray">
                        {{ remainingAccounts }} Accounts Remaining
                    </Text>
                    <Text v-else size="f-9" color="gray">Unlimited Remaining</Text>
                </div>
                <!-- Total Spend -->
                <div class="monthly-spend-bar">
                    <Text as="h5" style="font-size: 1.125rem" weight="600">
                        <Money :value="last30DaySpend" currency="USD" /> per month
                    </Text>
                    <Spacer height="0.875rem" />
                    <ProgressBar
                        id="progressBar"
                        :progress="spendRatio"
                        :barColor="accountPBColor"
                    />
                    <Spacer height="0.75rem" />
                    <Text v-if="currentPlan.spend_limit" size="f-9" color="gray">
                        <Money :value="remainingSpend" currency="USD" /> Spend Remaining
                    </Text>
                    <Text v-else size="f-9" color="gray">Unlimited Remaining</Text>
                </div>
            </div>
        </div>
    </SettingsSection>
    <!-- Plan Selection Modal -->
    <PlanSelectionModal />
</template>

<script lang="ts">
import { computed } from 'vue'
import max from 'lodash-es/max'

import { oButton, Text, Money, Spacer } from '@opteo/components-next'

import { useSubscription } from '@/composition/billing/useSubscription'

import SettingsSection from '@/components/global/SettingsSection.vue'
import ProgressBar from '@/components/global/ProgressBar.vue'
import { useUser } from '@/composition/user/useUser'
import PlanSelectionModal from '@/components/billing/PlanSelectionModal.vue'
import { usePlanSelectionModal } from '@/composition/billing/usePlanSelectionModal'

export default {
    name: 'SubscriptionOverview',
    components: { Money, oButton, Text, Spacer, ProgressBar, PlanSelectionModal, SettingsSection },
    setup() {
        const { userType, coupon, nextChargeDate } = useSubscription()

        const { currentPlan } = useUser()

        const { userInfo } = useUser()

        const nextInvoice = computed(() => {
            if (userType.value.includes('cancel')) {
                return 0
            }
            if (!coupon.value) {
                return currentPlan.value?.price ?? 0
            }
            if (coupon.value.amount_off) {
                return Math.max((currentPlan.value?.price ?? 0) - coupon.value.amount_off / 100, 0)
            }
            const percent_off = 1 - (coupon.value.percent_off ?? 0) / 100 ?? 1
            return (currentPlan.value?.price ?? 0) * percent_off
        })

        const last30DaySpend = computed(() => userInfo.value?.total_usd_spend_last_30d ?? 0)
        const totalActiveAccounts = computed(() => userInfo.value?.total_active_accounts ?? 0)

        const remainingSpend = computed(() =>
            max([(currentPlan.value?.spend_limit ?? 0) - last30DaySpend.value, 0])
        )

        const spendRatio = computed(() => {
            if (last30DaySpend.value > (currentPlan.value?.spend_limit ?? 0)) {
                return 1.1
            }
            return last30DaySpend.value / (currentPlan.value?.spend_limit ?? 0)
        })

        const remainingAccounts = computed(() =>
            max([(currentPlan.value?.account_limit ?? 0) - totalActiveAccounts.value, 0])
        )

        const accountRatio = computed(() => {
            if (totalActiveAccounts.value > (currentPlan.value?.account_limit ?? 0)) {
                return 1.1
            }
            return totalActiveAccounts.value / (currentPlan.value?.account_limit ?? 0)
        })

        const accountPBColor = computed(() => {
            if (spendRatio.value > 1 && currentPlan.value?.spend_limit) {
                return 'opteo-red'
            } else if (!currentPlan.value?.spend_limit) {
                return 'client-green'
            }
        })

        const spendPBColor = computed(() => {
            if (accountRatio.value > 1 && currentPlan.value?.account_limit) {
                return 'opteo-red'
            } else if (!currentPlan.value?.account_limit) {
                return 'client-green'
            }
        })

        const { showPlanSelectionModal } = usePlanSelectionModal()

        function openPlansModal() {
            showPlanSelectionModal.value = true
        }

        return {
            currentPlan,
            nextInvoice,
            nextChargeDate,
            remainingSpend,
            spendRatio,
            remainingAccounts,
            accountRatio,
            totalActiveAccounts,
            last30DaySpend,
            accountPBColor,
            spendPBColor,
            openPlansModal,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.subscription-overview-container {
    @include ph-32;
    @include pt-28;
    @include pb-32;
}
.subscription-overview-bars {
    @include container;
}
.linked-accounts-bar,
.monthly-spend-bar {
    @include ph-32;
    @include pv-28;
    border-bottom: 1px solid;
    @include opteo-border;
}
.monthly-spend-bar {
    border-bottom: none;
}
</style>
