import { computed, ref } from 'vue'
import keyBy from 'lodash-es/keyBy'

import { useWindowSize } from '@vueuse/core'

import { IS, Api } from '@opteo/types'
import { useUser } from '@/composition/user/useUser'
import { useTeam } from '@/composition/user/useTeam'
import { useAPI, Endpoint } from '@/composition/api/useAPI'

import { Routes } from '@/router/routes'
import { useRouter } from 'vue-router'

import subtractDays from 'date-fns/subDays'
import format from 'date-fns/format'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import parseISO from 'date-fns/parseISO'

// custom helper types 
export type AccountData = {
    name: string
    color: string
    platform: number
    initials: string
}

export type AccountBreakdown = {
    improvements: IS.ImprovementCostSavings[]
    pauseSpend: IS.PauseSpendCostSavings
    domainInfo: AccountData
    domainId: string | number
}

export default function useImpactStatistics() {
    const { groupId } = useUser()
    const { team } = useTeam()

    // router
    const router = useRouter()

    // Time period
    const fromDate = ref(new Date(2000, 0)) // Arbitrary date before creation of Opteo because default = all time
    const toDate = ref(subtractDays(new Date(), 1))

    // Window size util for calculating optimal modal table rows
    const viewportHeight = useWindowSize().height

    // data
    const {
        data: costSavings,
        loading: costSavingsLoading,
        isValidating: costSavingsValidating,
        error: costSavingsError,
        mutate: mutateCostSavings,
    } = useAPI<IS.GetCostSavedResponse>(Endpoint.GetImpactStatisticsCostSavings, {
        body: () => ({ teamId: groupId.value, fromDate: fromDate.value, toDate: toDate.value }),
        waitFor: () => groupId.value,
    })

    const {
        data: timeSavings,
        loading: timeSavingsLoading,
        isValidating: timeSavingsValidating,
        error: timeSavingsError,
        mutate: mutateTimeSavings,
    } = useAPI<IS.GetTimeSavedResponse>(Endpoint.GetImpactStatisticsTimeSavings, {
        body: () => ({ teamId: groupId.value, fromDate: fromDate.value, toDate: toDate.value }),
        waitFor: () => groupId.value,
    })

    const {
        data: criticalAlerts,
        loading: criticalAlertsLoading,
        isValidating: criticalAlertsValidating,
        error: criticalAlertsError,
        mutate: mutateCriticalAlerts,
    } = useAPI<IS.GetCriticalAlertCountsResponse>(Endpoint.GetImpactStatisticsCriticalAlerts, {
        body: () => ({ teamId: groupId.value, fromDate: fromDate.value, toDate: toDate.value }),
        waitFor: () => groupId.value,
    })

    const {
        data: userStatistics,
        loading: userStatisticsLoading,
        isValidating: userStatisticsValidating,
        error: userStatisticsError,
        mutate: mutateUserStatistics,
    } = useAPI<IS.GetUserStatisticsResponse>(Endpoint.GetImpactStatisticsUserStatistics, {
        body: () => ({ teamId: groupId.value, fromDate: fromDate.value, toDate: toDate.value }),
        waitFor: () => groupId.value,
    })

    const {
        data: calendarHeatMap,
        loading: calendarHeatMapLoading,
        isValidating: calendarHeatMapValidating,
        error: calendarHeatMapError,
    } = useAPI<IS.GetCalendarHeatMapResponse>(Endpoint.GetCalendarHeatMap, {
        body: () => ({ teamId: groupId.value, fromDate: fromDate.value, toDate: toDate.value }),
        waitFor: () => groupId.value,
    })

    const dataLoading = computed(
        () =>
            userStatisticsLoading.value ||
            costSavingsLoading.value ||
            criticalAlertsLoading.value ||
            timeSavingsLoading.value ||
            calendarHeatMapLoading.value
    )

    const loadingProgress = computed(() => {
        // using unary operator to turn the bool values into numbers, 0 or 1
        return (
            +!userStatisticsLoading.value +
            +!costSavingsLoading.value +
            +!timeSavingsLoading.value +
            +!criticalAlertsLoading.value +
            +!calendarHeatMapLoading.value
        )
    })

    const downloadCSV = ({ file, name }: { file: string | undefined, name: string }) => {
        const anchor = document.createElement('a')
        if (!file) return
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(file)
        anchor.target = '_blank'
        anchor.download = `${name}_${format(new Date(), 'dd_MM_yyyy')}.csv`
        anchor.click()
    }

    function setNewDateRangeStart(startDate: Date) {
        fromDate.value = startDate
        mutateCostSavings()
        mutateTimeSavings()
        mutateCriticalAlerts()
        mutateUserStatistics()
    }

    function isValidIso(val: string) {
        const d = new Date(val)
        return !Number.isNaN(d.valueOf()) && d.toISOString() === val
    }

    function formatDateFromTimestamp(ts: string | Date) {
        if (ts instanceof Date) return format(ts, `MMMM do y`)
        // Check if the date is iso, if not return N/A
        if (!isValidIso(ts)) return 'N/A'
        else return format(parseISO(ts), `MMMM do y`)
        //format(parseISO(ts), `MMMM do y 'at' h:mmaaa`) if we want time
    }

    const { data: teamAccounts, loading: teamAccountsLoading } = useAPI<
        IS.GetTeamAccountsResponse[]
    >(Endpoint.GetAllAccountsForTeam, {
        body: () => ({ teamId: groupId.value }),
        waitFor: () => groupId.value,
    })

    const teamAccountsByDomainId = computed(() => {
        if (!teamAccounts.value) return {}
        return keyBy(teamAccounts.value, 'domainId')
    })

    const teamAccountsByAccountId = computed(() => {
        if (!teamAccounts.value) return {}
        return keyBy(teamAccounts.value, 'accountId')
    })

    const teamSize = computed(() => {
        return team.value?.length || 1  // "1" is to avoid errors while teamSize is undefined 
    })

    const getAccountInfoFromList = (accountId: string) => {
        const account = teamAccountsByAccountId.value?.[accountId] // could add Team.Account type for item param
        if (!account) {
            console.error('Domain not found in team accounts', accountId)
        }

        return {
            name: account?.name,
            color: account?.color,
            platform: account?.platform,
            initials: account?.initials,
        }
    }

    const getDomainInfoFromList = (domainId: number) => {
        const domain = teamAccountsByDomainId.value?.[domainId] // could add Team.Account type for item param
        if (!domain) {
            console.error('Domain not found in team accounts', domainId)
        }

        return {
            name: domain?.name,
            color: domain?.color,
            platform: domain?.platform,
            initials: domain?.initials,
        }
    }

    const calculateAveragePerWeek = ({ dateOfFirstInstance, count }: { dateOfFirstInstance: string | Date, count: number }) => {
        // if for some reason the date of first instance is underfined, just return 0
        if (!dateOfFirstInstance) return 0
        const formattedDateOfFirstInstance = dateOfFirstInstance instanceof Date ? dateOfFirstInstance : parseISO(dateOfFirstInstance)
        // Calculate number of weeks elapsed since first instance, but ensure it's at least 1
        let numberOfWeeks = differenceInWeeks(new Date(), formattedDateOfFirstInstance)
        if (numberOfWeeks < 1) {
            numberOfWeeks = 1
        }
        // divide count by number of weeks
        return Math.round((count / numberOfWeeks) * 100) / 100 // At most 2 decimals; many numbers will be small so decimals are informative
    }

    const generateImprovementRouterLink = ({ domainId, improvementId }: { domainId: number | string, improvementId: number | string }) => {
        const routerLink = router.resolve({
            name: Routes.CompletedImprovement,
            params: { accountId: domainId, improvementId: improvementId },
        })
        return window.location.protocol + '//' + window.location.host + routerLink.fullPath
    }

    const generateAlertRouterLink = (alertHashId: number | string) => {
        const routerLink = router.resolve({
            name: Routes.Alert,
            params: { alertId: alertHashId },
        })
        return window.location.protocol + '//' + window.location.host + routerLink.fullPath
    }

    const generatePauseSpendRouterLink = ({ domainId }: { domainId: string | number }) => {
        const routerLink = router.resolve({
            name: Routes.DomainSettings,
            params: { accountId: domainId },
            hash: '#update-budget',
        })

        // Could add an anchor or scroll position to send user straight to budget section, where pause spend feature is

        return window.location.protocol + '//' + window.location.host + routerLink.fullPath
    }

    const openUrlInNewTab = (url: string) => {
        window.open(url, '_blank')
    }

    return {
        // cost savings
        costSavings,
        costSavingsLoading,
        costSavingsValidating,
        costSavingsError,
        // time savings
        timeSavings,
        timeSavingsLoading,
        timeSavingsValidating,
        timeSavingsError,
        // critical alerts
        criticalAlerts,
        criticalAlertsLoading,
        criticalAlertsValidating,
        criticalAlertsError,
        // user statistics
        userStatistics,
        userStatisticsLoading,
        userStatisticsValidating,
        userStatisticsError,
        // Calendar
        calendarHeatMap,
        calendarHeatMapLoading,
        calendarHeatMapValidating,
        calendarHeatMapError,
        // time
        setNewDateRangeStart,
        // util
        downloadCSV,
        formatDateFromTimestamp,
        teamAccounts,
        teamSize,
        getDomainInfoFromList,
        getAccountInfoFromList,
        teamAccountsLoading,
        calculateAveragePerWeek,
        generateImprovementRouterLink,
        generateAlertRouterLink,
        generatePauseSpendRouterLink,
        openUrlInNewTab,
        viewportHeight,
        dataLoading,
        loadingProgress,
        team,
    }
}
