import { ref, Ref, computed, watch, reactive, onMounted, cloneVNode } from 'vue'
import { Endpoint, useAPI } from '@/composition/api/useAPI'
import { useDomain } from '@/composition/domain/useDomain'
import { useNumber, useMoney, usePercent, useRoas } from '@opteo/components-next'
import { sumBy, capitalize as capitalise } from '@/lib/lodash'
import { format as formatDate, subDays } from 'date-fns'
import debounce from 'lodash-es/debounce'

import {
    TimePeriod,
    RawChartData,
    TableDataRanges,
    DataType,
    TableData,
    ConversionAction,
    TableItem,
} from './types'
import { usePerformanceControls } from './usePerformanceControls'
import { Targets } from '@opteo/types'
import { adaptDateToTimezone } from '../misc/useTimezone'
import { useAccount } from '../account/useAccount'

const CONVERSION_VAL_KEY = 'conversion_value:::'
const CONVERSIONS_KEY = 'conversions:::'

export function useTable() {
    const { domainId, domainInfo, performanceMode } = useDomain()
    const { currencyCode } = useAccount()

    const {
        // DATE RANGE
        extendedStartDate,
        startDate,
        endDate,
        // SIDEBAR
        sidebarOpen,
        // CAMPAIGNS
        accountDataLoading,
        channels,
        selectedCampaignIds,
        selectedCampaignCount,
        entireChannelSelected,
        toggleChannel,
        toggleCampaign,
        // CONVERSION ACTIONS
        conversionActions,
        selectedConversionActions,
        selectedConversionActionCount,
        toggleConversionAction,
    } = usePerformanceControls()

    const previousRangeText = computed(() => {
        return `${formatDate(extendedStartDate.value, 'do LLL')} → ${formatDate(
            subDays(startDate.value, 1),
            'do LLL'
        )}`
    })

    const selectedRangeText = computed(() => {
        return `${formatDate(startDate.value, 'do LLL')} → ${formatDate(endDate.value, 'do LLL')}`
    })

    const tableHeaders = [
        { key: 'type', text: 'Type' },
        { key: 'metric', text: 'Metric', width: 420 },
        reactive({ key: 'previousRange', text: previousRangeText }),
        reactive({ key: 'selectedRange', text: selectedRangeText }),
        { key: 'delta', text: '% Change' },
    ]

    const {
        data: rawTableData,
        loading: tableDataLoading,
        isValidating: tableDataIsValidating,
        mutate: updateTableData,
    } = useAPI<RawChartData[]>(Endpoint.GetPerformanceData, {
        body: () => ({
            domain_id: domainId.value,
            campaign_ids: selectedCampaignIds.value,
            raw_from_date: extendedStartDate.value,
            raw_to_date: endDate.value,
            time_period: TimePeriod.DAY,
        }),
        uniqueId: () =>
            `${selectedCampaignIds.value.join(',')}:${selectedConversionActions.value.join(',')}`,
        waitFor: () => channels.value.length && currencyCode.value,
    })

    const initialTableDataLoading = ref(true)

    watch(domainId, () => {
        initialTableDataLoading.value = true
    })

    watch(tableDataLoading, () => {
        if (!tableDataLoading.value) {
            initialTableDataLoading.value = false
        }
    })

    const tableDataUpdatedDebounced = ref(false)
    const debouncedUpdateTableData = debounce(() => {
        updateTableData()
        tableDataUpdatedDebounced.value = false
    }, 500)

    watch([extendedStartDate, startDate, endDate], () => {
        debouncedUpdateTableData.cancel()
        updateTableData()
    })

    watch([selectedCampaignIds, selectedConversionActions], () => {
        if (!initialTableDataLoading.value) {
            tableDataUpdatedDebounced.value = true
            debouncedUpdateTableData.cancel()
            debouncedUpdateTableData()
        }
    })

    const tableDataRanges: Ref<TableDataRanges> = ref({
        selectedRangeTableData: resetTableDataRange(),
        previousRangeTableData: resetTableDataRange(),
    })

    const tableConversionActions: Ref<ConversionAction[]> = ref([])

    function resetTableDataRange() {
        return {
            dates: [],
            searchImpressionShare: 0,
            impressionsLostToBudget: 0,
            impressionsLostToRank: 0,
            conversionValue: 0,
        }
    }

    const refresh = () => {
        if (!rawTableData.value || !rawTableData.value.length) {
            return
        }

        const selectedRangeTableData: TableData = resetTableDataRange()
        const previousRangeTableData: TableData = resetTableDataRange()
        tableConversionActions.value = []

        // IMPRESSION SHARE
        let totalSelectedMarketImpressions = 0
        let totalPreviousMarketImpressions = 0
        let totalSelectedImpressionsLostToBudget = 0
        let totalPreviousImpressionsLostToBudget = 0
        let totalSelectedImpressionsLostToRank = 0
        let totalPreviousImpressionsLostToRank = 0

        rawTableData.value.forEach(day => {
            const date = adaptDateToTimezone(new Date(day.day))

            const isDayInSelectedRange =
                formatDate(date, 'yyyy-MM-dd') >= formatDate(startDate.value, 'yyyy-MM-dd')

            // CONVERSION ACTIONS
            Object.entries(day).forEach(([key, val]) => {
                const keyIsConversion = key.includes(CONVERSIONS_KEY)

                const conversionIsSelected = selectedConversionActions.value
                    .map(c => c.id)
                    .includes(key.replace(CONVERSIONS_KEY, ''))

                const keyIsConversionValue = key.includes(CONVERSION_VAL_KEY)

                const conversionActionIsSelected = selectedConversionActions.value
                    .map(c => c.id)
                    .includes(key.replace(CONVERSION_VAL_KEY, ''))

                if (
                    (keyIsConversion && conversionIsSelected) ||
                    (keyIsConversionValue && conversionActionIsSelected)
                ) {
                    const conv = selectedConversionActions.value.find(
                        c => c.id === key.split(':::')[1]
                    )
                    if (!conv) throw new Error('should not happen')
                    const { label: name, id } = conv

                    let conversionAction = tableConversionActions.value.find(ca => ca.id === id)

                    if (!conversionAction) {
                        tableConversionActions.value.push({
                            id,
                            name,
                            selectedConversions: 0,
                            previousConversions: 0,
                            selectedConversionValue: 0,
                            previousConversionValue: 0,
                        })

                        conversionAction = tableConversionActions.value.find(ca => ca.id === id)
                    }

                    if (keyIsConversionValue) {
                        if (isDayInSelectedRange) {
                            selectedRangeTableData.conversionValue += +val
                        } else {
                            previousRangeTableData.conversionValue += +val
                        }
                    }

                    if (!conversionAction) return

                    if (keyIsConversionValue) {
                        if (isDayInSelectedRange) {
                            conversionAction.selectedConversionValue += val
                        } else {
                            conversionAction.previousConversionValue += val
                        }
                    } else {
                        if (isDayInSelectedRange) {
                            conversionAction.selectedConversions += val
                        } else {
                            conversionAction.previousConversions += val
                        }
                    }
                }
            })

            // IMPRESSION SHARE
            if (isDayInSelectedRange) {
                selectedRangeTableData.dates.push(day)
                totalSelectedMarketImpressions += +day.market_impressions
                totalSelectedImpressionsLostToBudget += +day.impressions_lost_to_budget
                totalSelectedImpressionsLostToRank += +day.impressions_lost_to_rank
            } else {
                previousRangeTableData.dates.push(day)
                totalPreviousMarketImpressions += +day.market_impressions
                totalPreviousImpressionsLostToBudget += +day.impressions_lost_to_budget
                totalPreviousImpressionsLostToRank += +day.impressions_lost_to_rank
            }
        })

        // IMPRESSION SHARE
        selectedRangeTableData.impressionsLostToBudget = totalSelectedMarketImpressions
            ? totalSelectedImpressionsLostToBudget / totalSelectedMarketImpressions
            : 0
        selectedRangeTableData.impressionsLostToRank = totalSelectedMarketImpressions
            ? totalSelectedImpressionsLostToRank / totalSelectedMarketImpressions
            : 0
        selectedRangeTableData.searchImpressionShare =
            1 -
            selectedRangeTableData.impressionsLostToBudget -
            selectedRangeTableData.impressionsLostToRank

        previousRangeTableData.impressionsLostToBudget = totalPreviousMarketImpressions
            ? totalPreviousImpressionsLostToBudget / totalPreviousMarketImpressions
            : 0
        previousRangeTableData.impressionsLostToRank = totalPreviousMarketImpressions
            ? totalPreviousImpressionsLostToRank / totalPreviousMarketImpressions
            : 0
        previousRangeTableData.searchImpressionShare =
            1 -
            previousRangeTableData.impressionsLostToBudget -
            previousRangeTableData.impressionsLostToRank

        if (!channels.value.find(c => c.channel === 'Search')?.campaigns.find(c => c.selected)) {
            if (
                selectedRangeTableData.impressionsLostToBudget === 0 &&
                selectedRangeTableData.impressionsLostToRank === 0
            ) {
                selectedRangeTableData.searchImpressionShare = 0
            }

            if (
                previousRangeTableData.impressionsLostToBudget === 0 &&
                previousRangeTableData.impressionsLostToRank === 0
            ) {
                previousRangeTableData.searchImpressionShare = 0
            }
        }

        // TABLE DATA
        tableDataRanges.value.selectedRangeTableData = selectedRangeTableData
        tableDataRanges.value.previousRangeTableData = previousRangeTableData

        tableItems.value = buildTableItems()
    }

    watch([rawTableData, selectedConversionActions], () => {
        refresh()
    })

    onMounted(() => refresh())

    function buildTableItem({
        id,
        type = '',
        metric,
        dataType,
        positiveDeltaIsGood = true,
        sumDataItems,
    }: {
        id: string
        type?: string
        metric: string
        dataType: DataType
        positiveDeltaIsGood?: boolean
        sumDataItems: (data: TableData) => number
    }): TableItem {
        const { selectedRangeTableData: selected, previousRangeTableData: previous } =
            tableDataRanges.value

        const selectedTotal = sumDataItems(selected)
        const previousTotal = sumDataItems(previous)

        const deltaValue = previousTotal ? (selectedTotal - previousTotal) / previousTotal : 0
        const delta = { delta: deltaValue, reverse: !positiveDeltaIsGood }

        let selectedRange = ''
        let previousRange = ''

        switch (dataType) {
            case DataType.NUMBER:
                selectedRange = useNumber({ value: selectedTotal }).displayValue.value
                previousRange = useNumber({ value: previousTotal }).displayValue.value
                break
            case DataType.MONEY:
                selectedRange = useMoney({
                    value: selectedTotal,
                    currency: currencyCode.value,
                }).displayValue.value
                previousRange = useMoney({
                    value: previousTotal,
                    currency: currencyCode.value,
                }).displayValue.value
                break
            case DataType.PERCENT:
                selectedRange = usePercent({ value: selectedTotal }).displayValue.value
                previousRange = usePercent({ value: previousTotal }).displayValue.value
                break
        }

        return {
            id,
            type,
            metric,
            selectedRange,
            previousRange,
            delta,
        }
    }

    const totalSelectedCost = computed(() =>
        sumBy(tableDataRanges.value.selectedRangeTableData.dates, ({ cost }) => +cost)
    )
    const totalPreviousCost = computed(() =>
        sumBy(tableDataRanges.value.previousRangeTableData.dates, ({ cost }) => +cost)
    )
    const totalSelectedClicks = computed(() =>
        sumBy(tableDataRanges.value.selectedRangeTableData.dates, ({ clicks }) => +clicks)
    )
    const totalPreviousClicks = computed(() =>
        sumBy(tableDataRanges.value.previousRangeTableData.dates, ({ clicks }) => +clicks)
    )

    function buildTableConversions(): TableItem[] {
        return tableConversionActions.value.flatMap((ca, index) => {
            console.log(ca)
            const conversionName = ca.name

            const selectedConversions = ca.selectedConversions
            const previousConversions = ca.previousConversions

            const selectedConversionValue = ca.selectedConversionValue
            const previousConversionValue = ca.previousConversionValue

            const conversionsDelta = previousConversions
                ? (selectedConversions - previousConversions) / previousConversions
                : 0

            const conversionValueDelta = previousConversionValue
                ? (selectedConversionValue - previousConversionValue) / previousConversionValue
                : 0

            const selectedCpa = ca.selectedConversions
                ? totalSelectedCost.value / ca.selectedConversions
                : 0
            const previousCpa = ca.previousConversions
                ? totalPreviousCost.value / ca.previousConversions
                : 0

            const cpaDelta = previousCpa ? (selectedCpa - previousCpa) / previousCpa : 0

            const selectedRoas = ca.selectedConversionValue
                ? ca.selectedConversionValue / totalSelectedCost.value
                : 0

            const previousRoas = ca.previousConversionValue
                ? ca.previousConversionValue / totalPreviousCost.value
                : 0

            const roasDelta = (selectedRoas - previousRoas) / previousRoas

            const selectedCr = totalSelectedClicks
                ? ca.selectedConversions / totalSelectedClicks.value
                : 0
            const previousCr = totalPreviousClicks
                ? ca.previousConversions / totalPreviousClicks.value
                : 0
            const crDelta = previousCr ? (selectedCr - previousCr) / previousCr : 0

            return [
                performanceMode.value === Targets.PerformanceMode.CPA
                    ? {
                          id: conversionName,
                          type: index === 0 ? 'Conversion' : '',
                          metric: conversionName,
                          selectedRange: useNumber({ value: selectedConversions }).displayValue
                              .value,
                          previousRange: useNumber({ value: previousConversions }).displayValue
                              .value,
                          delta: { delta: conversionsDelta, reverse: false },
                      }
                    : {
                          id: `${conversionName}`,
                          type: index === 0 ? 'Conversion Value' : '',
                          metric: `${conversionName}`,
                          selectedRange: useMoney({
                              value: selectedConversionValue,
                              currency: currencyCode.value,
                          }).displayValue.value,
                          previousRange: useMoney({
                              value: previousConversionValue,
                              currency: currencyCode.value,
                          }).displayValue.value,
                          delta: { delta: conversionValueDelta, reverse: false },
                      },
                performanceMode.value === Targets.PerformanceMode.CPA
                    ? {
                          id: `${conversionName} (CPA)`,
                          type: '',
                          metric: `${conversionName} (CPA)`,
                          selectedRange: useMoney({
                              value: selectedCpa,
                              currency: currencyCode.value,
                          }).displayValue.value,
                          previousRange: useMoney({
                              value: previousCpa,
                              currency: currencyCode.value,
                          }).displayValue.value,
                          delta: { delta: cpaDelta, reverse: true },
                      }
                    : {
                          id: `${conversionName} (ROAS)`,
                          type: '',
                          metric: `${conversionName} (ROAS)`,
                          selectedRange: useRoas({
                              value: selectedRoas,
                          }).displayValue.value,
                          previousRange: useRoas({
                              value: previousRoas,
                          }).displayValue.value,
                          delta: { delta: roasDelta, reverse: false },
                      },
                {
                    id: `${conversionName} (CR)`,
                    type: '',
                    metric: `${conversionName} (CR)`,
                    selectedRange: usePercent({ value: selectedCr }).displayValue.value,
                    previousRange: usePercent({ value: previousCr }).displayValue.value,
                    delta: { delta: crDelta, reverse: false },
                },
            ]
        })
    }

    function buildTableTotals(): TableItem[] {
        const totalSelectedConversions = sumBy(
            tableConversionActions.value,
            ca => ca.selectedConversions
        )
        const totalPreviousConversions = sumBy(
            tableConversionActions.value,
            ca => ca.previousConversions
        )
        const conversionsDelta = totalPreviousConversions
            ? (totalSelectedConversions - totalPreviousConversions) / totalPreviousConversions
            : 0

        const selectedCpa = totalSelectedConversions
            ? totalSelectedCost.value / totalSelectedConversions
            : 0
        const previousCpa = totalPreviousConversions
            ? totalPreviousCost.value / totalPreviousConversions
            : 0
        const cpaDelta = previousCpa ? (selectedCpa - previousCpa) / previousCpa : 0

        const selectedCr = totalSelectedClicks
            ? totalSelectedConversions / totalSelectedClicks.value
            : 0
        const previousCr = totalPreviousClicks
            ? totalPreviousConversions / totalPreviousClicks.value
            : 0
        const crDelta = previousCr ? (selectedCr - previousCr) / previousCr : 0

        return [
            {
                id: 'conversions',
                type: 'Totals',
                metric: 'Conversions',
                selectedRange: useNumber({ value: totalSelectedConversions }).displayValue.value,
                previousRange: useNumber({ value: totalPreviousConversions }).displayValue.value,
                delta: { delta: conversionsDelta, reverse: false },
            },
            {
                id: 'cpa',
                type: '',
                metric: 'Cost Per Conversion',
                selectedRange: useMoney({ value: selectedCpa, currency: currencyCode.value })
                    .displayValue.value,
                previousRange: useMoney({ value: previousCpa, currency: currencyCode.value })
                    .displayValue.value,
                delta: { delta: cpaDelta, reverse: true },
            },
            {
                id: 'cr',
                type: '',
                metric: 'Conversion Rate',
                selectedRange: usePercent({ value: selectedCr }).displayValue.value,
                previousRange: usePercent({ value: previousCr }).displayValue.value,
                delta: { delta: crDelta, reverse: false },
            },
        ]
    }

    const tableItems = ref<TableItem[]>([])

    function buildTableItems() {
        return [
            // ACQUISITION
            buildTableItem({
                id: 'impressions',
                type: 'Acquisition',
                metric: 'Impressions',
                dataType: DataType.NUMBER,
                sumDataItems: data => sumBy(data.dates, ({ impressions }) => +impressions),
            }),
            buildTableItem({
                id: 'clicks',
                metric: 'Clicks',
                dataType: DataType.NUMBER,
                sumDataItems: data => sumBy(data.dates, ({ clicks }) => +clicks),
            }),
            buildTableItem({
                id: 'ctr',
                metric: 'Click Through Rate',
                dataType: DataType.PERCENT,
                sumDataItems: data => {
                    const summedClicks = sumBy(data.dates, ({ clicks }) => +clicks)
                    const summedImpressions = sumBy(data.dates, ({ impressions }) => +impressions)
                    return summedImpressions ? summedClicks / summedImpressions : 0
                },
            }),
            buildTableItem({
                id: 'cost',
                metric: 'Cost',
                dataType: DataType.MONEY,
                positiveDeltaIsGood: false,
                sumDataItems: data => sumBy(data.dates, ({ cost }) => +cost),
            }),
            buildTableItem({
                id: 'cpc',
                metric: 'Cost Per Click',
                dataType: DataType.MONEY,
                positiveDeltaIsGood: false,
                sumDataItems: data => {
                    const summedCost = sumBy(data.dates, ({ cost }) => +cost)
                    const summedClicks = sumBy(data.dates, ({ clicks }) => +clicks)
                    return summedClicks ? summedCost / summedClicks : 0
                },
            }),
            buildTableItem({
                id: 'qs',
                metric: 'Quality Score',
                dataType: DataType.NUMBER,
                sumDataItems: data => sumBy(data.dates, ({ qs }) => +qs) / data.dates.length,
            }),
            // IMPRESSION SHARE
            buildTableItem({
                id: 'searchImpressionShare',
                type: 'Imp. Share',
                metric: 'Search Impression Share',
                dataType: DataType.PERCENT,
                sumDataItems: data => data.searchImpressionShare,
            }),
            buildTableItem({
                id: 'impressionsLostToRank',
                metric: 'Impressions Lost To Rank',
                dataType: DataType.PERCENT,
                positiveDeltaIsGood: false,
                sumDataItems: data => data.impressionsLostToRank,
            }),
            buildTableItem({
                id: 'impressionsLostToBudget',
                metric: 'Impressions Lost To Budget',
                dataType: DataType.PERCENT,
                positiveDeltaIsGood: false,
                sumDataItems: data => data.impressionsLostToBudget,
            }),
            // CONVERSIONS
            ...buildTableConversions(),
            // TOTALS
            ...buildTableTotals(),
            // FINANCIALS
            buildTableItem({
                id: 'conversionValue',
                type: 'Financials',
                metric: 'Conversion Value',
                dataType: DataType.MONEY,
                sumDataItems: data => data.conversionValue,
            }),
            buildTableItem({
                id: 'profit',
                metric: 'Value Minus Cost',
                dataType: DataType.MONEY,
                sumDataItems: data => data.conversionValue - sumBy(data.dates, ({ cost }) => +cost),
            }),
            buildTableItem({
                id: 'roas',
                metric: 'Return On Ad Spend',
                dataType: DataType.PERCENT,
                sumDataItems: data => {
                    const totalCost = sumBy(data.dates, ({ cost }) => +cost)
                    return totalCost
                        ? data.conversionValue / sumBy(data.dates, ({ cost }) => +cost)
                        : 0
                },
            }),
        ]
    }

    return {
        tableDataUpdatedDebounced,
        // TABLE
        tableDataRanges,
        tableHeaders,
        tableItems,
        tableDataLoading,
        tableDataIsValidating,
        // SIDEBAR
        sidebarOpen,
        capitalise,
        // CAMPAIGNS
        accountDataLoading,
        channels,
        selectedCampaignCount,
        entireChannelSelected,
        toggleChannel,
        toggleCampaign,
        // CONVERSION ACTIONS
        conversionActions,
        selectedConversionActionCount,
        toggleConversionAction,

        rawTableData,
    }
}
