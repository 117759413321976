import useMediaQuery from '@/composition/global/useMediaQuery'
import { ref, watch } from 'vue'
import { INTERCOM_APP_ID, LOAD_INTERCOM } from '@/lib/env'
import { IntercomAPI } from './api'
import initialize from './init'
import { RawIntercomBootProps, RawIntercomProps } from './types'

const APP_ID = INTERCOM_APP_ID as string

export type IntercomEvent =
    | 'completed_improvement'
    | 'set_cpa_target'
    | 'saved_campaign_groups'
    | 'report_created'
    | 'report_exported'
    | 'report_downloaded'
    | 'report_insights_opened'
    | '60_saw_client_list'
    | 'set_budget'
    | 'invited_team_member'
    | 'create_rsa_draft'
    | 'push_rsa'

const booted = ref(false)
export function useIntercom() {
    const meta: RawIntercomBootProps = {
        app_id: APP_ID,
    }

    const boot = () => {
        if (LOAD_INTERCOM) {
            initialize(APP_ID)
            IntercomAPI('boot', meta)
            booted.value = true
            updateMobileBubbleHidden()
        }
    }

    const shutdown = () => {
        if (!booted.value) {
            return
        }
        IntercomAPI('shutdown')
        booted.value = false
    }

    const bootAnonymous = () => {
        shutdown()
        boot()
        updateMobileBubbleHidden()
    }

    const hideBubble = () => {
        IntercomAPI('hide') // close the messenger if it is open
        update({ hide_default_launcher: true }) // hide the bubble
    }

    const _showBubble = () => update({ hide_default_launcher: false })
    const showBubble = () => {
        if (aboveMobile.value) {
            _showBubble()
        }
    }

    const openMessenger = () => IntercomAPI('show') // opens messenger
    const showNewMessage = (message?: string) => IntercomAPI('showNewMessage', message) // opens messenger with prefilled message in new conversation

    const update = (props?: RawIntercomProps) => IntercomAPI('update', props)

    const editUserAttributes = (
        attributes: Record<string, string | number | Date | boolean | { id: number }>
    ) => IntercomAPI('update', attributes)

    const trackEvent = (eventName: IntercomEvent, props?: object) => {
        IntercomAPI('trackEvent', eventName, props)
    }

    // Intercom bubble should be hidden on mobile
    const { aboveMobile } = useMediaQuery()
    const updateMobileBubbleHidden = () => (aboveMobile.value ? showBubble() : hideBubble())
    watch(aboveMobile, () => updateMobileBubbleHidden())

    return {
        bootAnonymous,
        shutdown,
        showBubble,
        hideBubble,
        showNewMessage,
        trackEvent,
        editUserAttributes,
        openMessenger,
    }
}
